import { addTimeout,removeTimeout } from 'redux-timeout';
//here. set decryptAndLoadErrand process ongoing
import update from 'immutability-helper';
import { I } from '../../../common/v5/config';
import { str2Int } from '../../../common/helpers';
import {
	getAdminTagSimpleList,
	getAdminTagList,
	getAgents,
	getFolders,
	getAgentGroups,
	getAgentAreas,
	getAgentTemplates,
	getAreas,
	getOrganizationOverviewAreas,
	getChannels,
	getErrandAgentStatistics,
	getErrandFetchAreaErrandCount,
	getExternalExpertQueries,
	getOneCollaborationQueryRecipients,
	getKnowledgeBaseList,
	postAdminTagNameAll,
	postCloseErrand,
	postCloseErrands,
	postDeleteErrands,
	postErrandAreas,
	postBulkSendErrands,
	postErrandChangeErrandAgent,
	postErrandList,
	postErrandWorkflowSettings,
	postErrandWorkflowLimitedSettings,
	postErrandQueueErrandToUser,
	postErrandTokenDecrypt,
	postOneErrandPreview,
	postAgentSetPreference,
	postErrandChangeErrandFolder,
	postAreaNotification,
	postErrandChangeErrandArea,
	postErrandForwardToArea,
	postErrandPinToTop,
	postErrandUpdatePriority,
	postErrandUpdateDueDate,
	postErrandReturnErrands,
	postLinkErrand,
	postQueueToMe,
	postClientAvatar,
	getNewIMCount,
	getSlaTime,
	getConnectedAgentAreas,
	getStatisticsAgentAreas,
	getCreateManualErrand,
	getAllActiveErrands,
	getIncomingEmailErrands,
	getAllActiveAgents,
	fetchForwardedErrands,
	fetchIncomingCollaborations,
	fetchExpiringErrands,
	fetchDueErrandsInAWeek,
	deleteLaunchpadWidget,
	addNewLaunchpadWidget,
	postOneBasicErrand
} from './ajax';
import {
	keyAdminTagNameAll,
	keyAdminTagSimpleList,
	keyAdminTagList,
	keyAgentAreas,
	keyAgentTemplates,
	keyAgents,
	keyFolders,
	keyAgentGroups,
	keyAreas,
	keyOrganizationOverviewAreas,
	keyChannels,
	keyCloseErrand,
	keyCloseErrands,
	keyDeleteErrands,
	keyBulkSendErrands,
	keyErrandAgentStatistics,
	keyErrandAreaData,
	keyAreasAgentsData,
	keyGetKnowledgeBaseList,
	keyErrandFetchAreaErrandCount,
	keyErrandList,
	keyErrandListChat,
	keyChatForwardArea,
	keyChatForwardAgent,
	keyErrandPreview,
	keyErrandUploadAnswerAttachment,
	keyErrandRemoveTemporaryAttachment,
	keyErrandWorkflowSettings,
	keyErrandTokenDecrypt,
	keyForwardToAgent,
	keyForwardToArea,
	keyGetErrands,
	keyMoveToFolder,
	keyQueueToMe,
	keyRetunToInbox,
	keyLinkErrand,
	keyWFExpertQueries,
	keyAgentSetErrandView,
	keyGetAreaNotification,
	// keyErrandForwardToArea,
	keyErrandPinToTop,
	keyErrandUpdatePriority,
	keyErrandUpdateDueDate,
	keyGetClientAvatar,
	keyGetSlaTime,
	keyConnectedAgentAreas,
	keyStatisticsAgentAreas,
	keyCreateManualErrand,
	keyIncomingEmailErrands,
	keyAllActiveErrands,
	keyAllActiveAgents,
	keyFetchForwardedErrands,
	keyFetchIncomingCollaborations,
	keyFetchDueErrandsInAWeek,
	keyFetchExpiringErrands,
	keyDeleteLaunchpadWidgets,
	keyAddLaunchpadWidgets,
	keyQueueBasicErrandInfo
} from '../../constants/keys';
import {
	XFER_DOMAIN_ERRAND_PREVIEW,
	XFER_DOMAIN_WF_EXPERT_QUERIES,
	GLOBAL_SEARCH_FROM_BODY
} from '../../constants/constants';
import {
	CTX_MY,
	CTX_NEW,
	CTX_REVIEW,
	D_CLIENT_AVATAR,
	D_FORWARD_AGENTS,
	D_TAGS_INFO,
	DEF_OPT,
	TMR_ERRAND_COUNTER,
	TMR_FETCH_LIST,
	TMR_FETCH_LIST_FEATURE,
	PREF_LIST_VIEW,
	TMR_IM_COUNTER,
	SOLIDUS,
	CL_OVERVIEW,
	CL_REPORT,
	EXECUTIVE_REPORT,
	VIEW_REPORT,
	AGENT_FAV_FORWARD_TO_AREA,
	SR_NUMBER_OF_CONTACTS,
	PREF_CONVERSATION_VIEW,
	CTX_POSTPONED,
	TMR_FETCH_LAUNCHPAD,
	WFP_BULK_SEND_ERRAND,
	RPLY_ERRAND
} from '../../../common/v5/constants';
import {
	getExtQueueType,
} from '../../../common/v5/helpers';
import {
	checkEmptyRecipient,
	createUpdateErrandObject,
	openSingleErrand,
	openSingleChatErrand,
	doPickupNext,
	loadAndOpenErrand,
	loadAndOpenErrandParams,
	moveErrandsToFolder,
	fetchValidErrandId,
	checkEmptyAnswer
} from './errand'; // TODO: remove cyclic between  workflow and errand
import { onLoadSippConn } from './sippRtc.js';
import {
	enableConfirm
	, updateAventaLogin
	, fetchAgentFavourite
} from './hmf';
import {
	togglePopAlert,
} from '../hmf';
import {
	changeSelectedFilteredAreas,
	setErrandMobileView,
	setListReady,
	selectToggleSideBar,
	showMultipleActions,
	setListParams,
	changeErrandListViewMode,
	setWfLimitedSettings,
	setAgentDataFromLocal,
	setErrandMsgTruncatedByDefault
} from '../workflow';
import {
	pinErrandToTop,
	togglePriority,
	classificationTagging,
	setPreviousErrand,
	setCurrentErrandOpening,
	signalViewErrandOnly
} from '../errand';
import {
	handleProcessing,
	handleResetOffset,
	doGlobalSearchByWS
} from '../search';
import {
	async,
	asyncCore,
	asyncDoneActionType,
	createBroadcaster,
	getAppState,
	getNormalizedDomain,
	getStateName,
	loadOnceCreator,
	mapKeyToActionsAndStateName,
	multiAsync,
	optInactiveNoReloadCreator,
	periodicPoll,
	makeDelayAsync
} from '../../util';
import { cachedAsync, tryCachedAsync } from './cache';
import {
	contextMemo,
	getTotalSelectedErrands,
	getSelectedErrandIds,
	getSelectedChatErrandIds,
	listInputs,
	selectedFilterAreaListStringMemoize
} from '../../selectors/workflow';
import {
    storeAgentData
	, getAgentData
} from '../../../common/v5/indexeddb';
import { getGroupedErrandListString } from '../../selectors/errand';
import { NewIMCounter } from '../../actions/internalMessages';
import { IsContextSearch } from '../../../common/v5/utils';
import {chartIdentifier} from '../../reducers/statistics';

export const workflow = mapKeyToActionsAndStateName([
	[keyAdminTagNameAll, 'adminTagNameAll'],
	[keyAdminTagSimpleList, 'adminTagSimpleList'],
	[keyAdminTagList, 'adminTagList'],
	[keyAgentAreas, 'agentAreas'],
	[keyAgentTemplates, 'agentTemplates'],
	[keyAgents, 'agents'],
	[keyFolders, 'folders'],
	[keyAgentGroups, 'agentGroups'],
	[keyAreas, 'areas'],
	[keyOrganizationOverviewAreas, 'organizationOverviewAreas'],
	[keyChannels, 'channels'],
	[keyCloseErrand, 'closeErrand'],
	[keyCloseErrands, 'closeErrands'],
	[keyDeleteErrands, 'deleteErrands'],
	[keyBulkSendErrands, 'bulkSendErrands'],
	[keyErrandAgentStatistics, 'agentStatistics'],
	[keyErrandAreaData, 'areaData'],
	[keyAreasAgentsData, 'areasagentsdata'],
	[keyGetKnowledgeBaseList, 'knowledgeBaseList'],
	[keyErrandFetchAreaErrandCount, 'areaErrandCount'],
	[keyErrandList, 'errandList'],
	[keyErrandListChat, 'errandListChat'],
	[keyChatForwardArea, 'chatForwardArea' ],
	[keyChatForwardAgent, 'chatForwardAgent' ],
	[keyErrandPreview, 'errandPreview'],
	[keyErrandUploadAnswerAttachment, 'uploadAnswerAttachment'],
	[keyErrandRemoveTemporaryAttachment, 'removeTempAttachment'],
	[keyErrandWorkflowSettings, 'fetchWfSettings'],
	[keyForwardToAgent, 'forwardToAgent'],
	[keyForwardToArea, 'forwardToArea'],
	[keyGetErrands, 'getErrands'],
	[keyMoveToFolder, 'moveToFolder'],
	[keyQueueToMe, 'queueToMe'],
	[keyErrandTokenDecrypt, 'errandTokenDecrypt'],
	[keyRetunToInbox, 'returnToInbox'],
	[keyLinkErrand, 'linkErrand'],
	[keyAgentSetErrandView, 'agentSetErrandView'],
	[keyGetAreaNotification, 'areaNotification'],
	// [keyErrandForwardToArea, 'forwardErrandsToArea'],
	[keyErrandPinToTop, "errandPinToTop"],
	[keyErrandUpdatePriority, 'setErrandsPriority'],
	[keyErrandUpdateDueDate, 'setErrandsDueDate'],
	[keyWFExpertQueries, 'wfExpertQueries'],
	[keyGetClientAvatar, 'clientAvatar'],
	[keyGetSlaTime,'getSlaTime'],
	[keyConnectedAgentAreas, 'connectedAgentAreas'],
	[keyStatisticsAgentAreas, 'statisticsAgentAreas'],
	[keyCreateManualErrand, 'createManualErrand'],
	[keyIncomingEmailErrands, 'incomingEmailsList'],
	[keyAllActiveErrands, 'allActiveEmailsErrands'],
	[keyAllActiveAgents, 'allActiveAgents'],
	[keyFetchForwardedErrands, 'allForwardedErrands'],
	[keyFetchIncomingCollaborations, 'allIncomingCollabs'],
	[keyFetchDueErrandsInAWeek, 'allDueErrandsInAWeek'],
	[keyFetchExpiringErrands, 'allExpiringErrands'],
	[keyDeleteLaunchpadWidgets, 'removeLaunchpadWidget'],
	[keyAddLaunchpadWidgets, 'addLaunchpadWidget'],
	[keyQueueBasicErrandInfo, 'basicQueueErrandInfo']
]);

const stateName = key => getStateName(workflow[key]);

const getWorkflowRoot = store => getAppState(store, 'workflow');

export const getWorkflowState = (store, key) =>
	getWorkflowRoot(store)[stateName(key)];

export const isErrandPreviewInProgress = state => getWorkflowState(
	state,
	keyErrandPreview
).wip

const loadOnce = (key, ajax) =>
	loadOnceCreator(workflow, key, ajax, getWorkflowState);

const loadOnceWithOptInactive = (
	key,
	ajax
) => optInactiveNoReloadCreator(workflow, key, ajax, getWorkflowState, true);

const asyncNew = (ajax, actionsMap, param, force) => asyncCore(true,
	getWorkflowRoot, ajax, actionsMap, param);

const multiAsyncNew = (ajax, actionsMap, param, force) => asyncCore(false,
	getWorkflowRoot, ajax, actionsMap, param);

export const fetchWorkflowExpertQueries = errandId => {
	const q = {errand: errandId};
	return cachedAsync(
		errandId,
		"expertQueries",
		async(getOneCollaborationQueryRecipients(errandId),
			workflow[keyWFExpertQueries], q),
		XFER_DOMAIN_WF_EXPERT_QUERIES
	);
};

export const fetchSlaTime = errandId => async(getSlaTime(errandId),
		workflow[keyGetSlaTime]
);

export const fetchWorkflowSettings = () => async(postErrandWorkflowSettings(),
	workflow[keyErrandWorkflowSettings]
);

export const onceFetchWorkflowSettings = loadOnce(keyErrandWorkflowSettings,
	postErrandWorkflowSettings);

export const loadWfLimitedSettings = (data) => (dispatch, getState) => {
	dispatch(setWfLimitedSettings(data));
	return Promise.resolve({data});
}

export const setAgentDataFromLocalDb = (data) =>(dispatch, getState) => {
	dispatch(setAgentDataFromLocal(data));
	return Promise.resolve({data});
}

export const onceFetchWorkflowLimitedSettings =
	loadOnce(keyErrandWorkflowSettings, postErrandWorkflowLimitedSettings);

const [	errandListAsync ] = makeDelayAsync(false, workflow[keyErrandList]);
export const errandList = (context, q) => errandListAsync(postErrandList(q), {context});

export const onceAreas = loadOnceWithOptInactive(keyAreas, getAreas);
export const onceOrganizationOverviewAreas = loadOnce(keyOrganizationOverviewAreas, getOrganizationOverviewAreas);

export const fetchAreaErrandCount = () => async(getErrandFetchAreaErrandCount(),
	workflow[keyErrandFetchAreaErrandCount]
);

export const errandPreview = errandId => cachedAsync(
	errandId,
	"previewData",
	async(postOneErrandPreview(errandId), workflow[keyErrandPreview]),
	XFER_DOMAIN_ERRAND_PREVIEW
);

export const getErrands = q => async(
	postErrandList(q)
	, workflow[keyGetErrands]
);

export const closeErrand = p => async(
	postCloseErrand(p)
	, workflow[keyCloseErrand]
);

export const closeErrands = p => async(
	postCloseErrands(p)
	, workflow[keyCloseErrands]
);

export const deleteErrands = p => async(
	postDeleteErrands(p)
	, workflow[keyDeleteErrands]
);

const bulkSendErrands = p => async(
	postBulkSendErrands(p)
	, workflow[keyBulkSendErrands]
);

export const sendingBulkErrand = () => (dispatch, getState) => {
	let option = {
		isBulkSend: true
	};
	let param = createUpdateErrandObject(null, option, getState());
	return dispatch(checkEmptyAnswer(param, option)).then(({param}) => {
		const ids = getSelectedErrandIds(getState());
		let list = {list: ids.join(",")};
		return dispatch(bulkSendErrands({...param, ...list}));
	});
}

// TODO: use broadcaster
export const onceAgentAreas = loadOnceWithOptInactive(
	keyAgentAreas
	, getAgentAreas
);

export const fetchAgentTemplates = () => async(getAgentTemplates(),
	workflow[keyAgentTemplates]
);

export const onceConnectedAgentAreas = loadOnceWithOptInactive(
	keyConnectedAgentAreas
	, getConnectedAgentAreas
);

export const onceStatisticsAgentAreas = loadOnceWithOptInactive(
	keyStatisticsAgentAreas
	, getStatisticsAgentAreas
);

export const onceAgents = loadOnceWithOptInactive(keyAgents, getAgents);

export const onceChannels = loadOnce(keyChannels, getChannels);

export const agentGroups = () => async(getAgentGroups(), workflow[keyAgentGroups]); //lwb test
export const onceAgentGroups = loadOnce(keyAgentGroups, getAgentGroups);

const onceKBListBase = loadOnce(keyGetKnowledgeBaseList, getKnowledgeBaseList);

export const onceKnowledgeBaseList = createBroadcaster(onceKBListBase);

export const onceFolders = loadOnce(keyFolders, getFolders);

export const onceAdminTagSimpleList = loadOnce(keyAdminTagSimpleList,
	getAdminTagSimpleList);

const allTagsName = tagIds => multiAsync(
	postAdminTagNameAll({ids: tagIds})
	, workflow[keyAdminTagNameAll]
);

export const onceAdminTagList = loadOnce(keyAdminTagList, getAdminTagList);

export const agentStatistics = () => async(getErrandAgentStatistics(),
	workflow[keyErrandAgentStatistics]
);

export const doQueueToMe = p => async(postErrandQueueErrandToUser(p),
	workflow[keyQueueToMe]
);

const doErrandTokenDecrypt = p => async(postErrandTokenDecrypt(p),
	workflow[keyErrandTokenDecrypt]
);

export const decryptAndLoadErrand = (cryptString, prevEid) => (dispatch, getState) => {
	console.info(cryptString);
	let param = {};
	param.token = cryptString;
	dispatch(doErrandTokenDecrypt(param))
		.then( rs =>{
			if(isNaN(rs.eid) == false){
				if(rs.isChat == false){
					let params = {}
					params.postMessageOpen = true
					dispatch(setCurrentErrandOpening(true));
					dispatch(loadAndOpenErrandParams(rs.eid, params))
					.then( rs=>{
						const wfSettings = getState().app.workflow.fetchWfSettings.data
						if(wfSettings.voiceReopenPreviousErrand == true){
							dispatch(setPreviousErrand(prevEid));
						}
						dispatch(setCurrentErrandOpening(false));
						console.info("previous errand is:",prevEid);
					});
				} else {
					externalqueue.isChat = true;
				}
			}
		});
};

const forwardToArea = p => async(postErrandChangeErrandArea(p),
	workflow[keyForwardToArea]
);

export const moveToFolderWithoutLoadList = p =>
	async(postErrandChangeErrandFolder(p), workflow[keyMoveToFolder]);

export const moveToFolder = (ids, cipherKeys, folderId) => (dispatch, getState) =>
	dispatch(moveErrandsToFolder(ids, cipherKeys, folderId))
		.then(() => {
			let currentContext = getState().app.workflow.filter.currentContext;
			if(IsContextSearch(currentContext)){
				dispatch(showMultipleActions(false));
				dispatch(handleProcessing(true));
				dispatch(handleResetOffset());
				dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));
			} else {
				dispatch(loadList("moveToFolder"));
			}
		});

export const forwardToAgent = p => async(postErrandChangeErrandAgent(p),
	workflow[keyForwardToAgent]
);

export const returnToInbox = p => async(postErrandReturnErrands(p),
	workflow[keyRetunToInbox]
);

export const linkErrandAsync = p => async(postLinkErrand(p),
	workflow[keyLinkErrand]
);

const fetchAvatars = (list, autoRefresh) => multiAsync(
	postClientAvatar({list, autoRefresh})
	, workflow[keyGetClientAvatar]
);

function checkIfValueExists(list, value) {
	let exists = false;
	$.each(list, (j,inner) => {
		if(inner.fromId === value) {
			exists = true;
			return false;
		}
	});
	return exists;
}

export const fetchAvatarByClient = (autoRefresh) => (dispatch, getState) => {
	const store = getState().app.workflow, erd = store.errandList.data.norm,
		chat = store.errandListChat, errandSearchResults = getState().app.search.results.errands;
	let list = [];
	// TODO: these piece of code just repeating in next two loops. Very good
	// candidate to be factored out.
	$.each(erd, (k,v) => {
		if(v.data && v.data.fromId > 0){
			if(list.length > 0){
				if(!checkIfValueExists(list, v.data.fromId)){
					const dd = getNormalizedDomain(getState().domain, "clientAvatar", v.data.fromId)
					if(typeof dd === 'undefined'){
						list.push({
							fromId: v.data.fromId,
							errand: v.data.id
						});
					}
				}
			} else {
				const dd = getNormalizedDomain(getState().domain, "clientAvatar", v.data.fromId)
				if(typeof dd === 'undefined'){
					list.push({
						fromId: v.data.fromId,
						errand: v.data.id
					});
				}
			}
		}
	});
	$.each(chat, (k,v) => {
		if(v.errand && v.errand.data.fromId > 0){
			if(list.length > 0){
				if(!checkIfValueExists(list, v.errand.data.fromId)){
					const dd = getNormalizedDomain(getState().domain, "clientAvatar", v.errand.data.fromId)
					if(typeof dd === 'undefined'){
						list.push({
							fromId: v.errand.data.fromId,
							errand: v.errand.data.id
						});
					}
				}
			} else {
				const dd = getNormalizedDomain(getState().domain, "clientAvatar", v.errand.data.fromId)
				if(typeof dd === 'undefined'){
					list.push({
						fromId: v.errand.data.fromId,
						errand: v.errand.data.id
					});
				}
			}
		}
	});
	$.each(errandSearchResults, (k,v) => {
		if(v && v.fromId > 0){
			if(list.length > 0){
				if(!checkIfValueExists(list, v.fromId)){
					const dd = getNormalizedDomain(getState().domain, "clientAvatar", v.fromId)
					if(typeof dd === 'undefined'){
						list.push({
							fromId: v.fromId,
							errand: v.id
						});
					}
				}
			} else {
				const dd = getNormalizedDomain(getState().domain, "clientAvatar", v.fromId)
				if(typeof dd === 'undefined'){
					list.push({
						fromId: v.fromId,
						errand: v.id
					});
				}
			}
		}
	});
	// Fetch client's avatar (if necessary) when on statistic menu
	const activeMainMenu = getState().app.menu.mainMenu.activeMainMenu;
	if (activeMainMenu === 'statistics') {
		const statistics = getState().app.statistics
			, activeView = statistics.view.active
			// , chartId = statistics.view.reportParamId.chart.id
			, charts = statistics.charts.byId
			;
		if (activeView == EXECUTIVE_REPORT ||
			(activeView == VIEW_REPORT && statistics.view.reportParamId &&
			statistics.view.reportParamId.chart.id == SR_NUMBER_OF_CONTACTS)) {
			let reportId = (activeView == EXECUTIVE_REPORT) ?
				chartIdentifier(CL_OVERVIEW, 1, 1) : chartIdentifier(CL_REPORT, 1, 0)
				, chart = charts[reportId]
				;
			if (chart && chart.data && chart.data.data) {
				$.each(chart.data.data, (k,v) => {
					if(v && v.email_fkey > 0){
						if(list.length > 0){
							if(!checkIfValueExists(list, v.email_fkey)){
								const dd = getNormalizedDomain(getState().domain, "clientAvatar", v.email_fkey)
								if(typeof dd === 'undefined'){
									list.push({
										fromId: v.email_fkey,
										serviceType: v.group1
									});
								}
							}
						} else {
							const dd = getNormalizedDomain(getState().domain, "clientAvatar", v.email_fkey)
							if(typeof dd === 'undefined'){
								list.push({
									fromId: v.email_fkey,
									serviceType: v.group1
								});
							}
						}
					}
				});
			}
		}
	}
	if (list.length > 0) {
		return dispatch(fetchAvatars(list, autoRefresh));
	}
	// caller expect promise to be return-ed
	return Promise.resolve();
};

export const fetchOneAvatar = (id, autoRefresh) => fetchAvatars([id], autoRefresh);

export const checkBeforeFetchAvatar = (id, autoRefresh) => tryCachedAsync(
	id
	, D_CLIENT_AVATAR
	, fetchOneAvatar(id, autoRefresh)
	, ([ data ]) => data
);

export const postErrandCount = (count, origins) => {
	let params = {}
	params.errandsCount = count;
	let apiparam = {};
	apiparam.action = "user-errands-count-changed";
	apiparam.params = params;
	for (const origin of origins) {
		let originUrl = origin.origin;
		if (origin.origin === 'file://') {
			originUrl = "*";
		}
		origin.source.postMessage(apiparam,originUrl);
	}
}

const rawLoadList = (autoRefresh, origin) => {
	return (dispatch, getState) => {
		dispatch(setListReady(false));
		const state = getState()
			, currentContext = contextMemo(state)
			;
		console.log('rawLoadList', currentContext);
		let wfs = listInputs(state);
		const wfSettings = getState().app.workflow.fetchWfSettings.data;
		let ui = state.app.workflow.ui;
		let chatErrandCount = state.app.workflow.errandListChat.length;
		if (wfs) {
			const filterList = selectedFilterAreaListStringMemoize(state);
			let { source } = wfs;
			if (currentContext === CTX_REVIEW
				|| source === CTX_NEW
				|| source === CTX_MY
				|| source === CTX_POSTPONED) {
				if (currentContext === CTX_REVIEW) {
					source = CTX_REVIEW;
				}
				if (currentContext === CTX_POSTPONED) {
					source = CTX_POSTPONED;
				}
				const sort = getWorkflowRoot(state).sort[source];
				const updater = {
					autoRefresh: {$set: autoRefresh}
					, filterList: {$set: filterList}
					, sort_expired_first: {$set: sort.expired_first}
					, sort_hp_first: {$set: sort.hp_first}
					, sort_replies_first: {$set: sort.replies_first}
					, sort_warnings_first: {$set: sort.warnings_first}
					, sort_collaboration_first: {$set: sort.collaboration_first}
					, origin: {$set: origin}
					, source: {$set: source}
				};
				if (currentContext === CTX_REVIEW) {
					// review context do not allow fetching by tag, folder not
					// user for time being.
					const setZero = {$set: 0};
					updater.classification = {$set: ""};
					updater.folder = setZero;
					updater.user = setZero;
				}
				if (currentContext === CTX_POSTPONED) {
					//Followed same idea like review does! 
					// review context do not allow fetching by tag, folder not
					// user for time being.
					const setZero = {$set: 0};
					updater.classification = {$set: ""};
					updater.folder = setZero;
					updater.user = setZero;
				}
				wfs = update(wfs, updater);
			} else {
				wfs = update(wfs, {autoRefresh: {$set: autoRefresh},
					origin: {$set: origin}});
			}
			if(typeof externalqueue !== "undefined" &&
				externalqueue.isExternal == true){
				return;
			}
			return dispatch(errandList(wfs.source, wfs))
				.then(() => {
					const state = getState()
						, { data } = getWorkflowState(state, keyErrandList)
						, { counterTags } = data
						, counters = state.domain.counters
						;
					if (counterTags && counterTags.length) {
						let ids = [];
						$.each(counterTags, (i,v) => {
							if (v == 0) {
								return;
							}
							if (!getNormalizedDomain(
								state.domain
								, D_TAGS_INFO
								, v)) {
								ids.push(v);
							}
						});
						if (ids.length) {
							dispatch(allTagsName(ids));
						}
					}
					if(wfSettings && wfSettings.agentErrandListViewPref == PREF_CONVERSATION_VIEW){
						dispatch(fetchAvatarByClient(autoRefresh));
					}
					dispatch(setListReady(true));
					if(ui.subscribeErrandCountPM.length > 0){
						postErrandCount(counters.my.count + chatErrandCount,
							ui.subscribeErrandCountPM);
					}

					// contextChanged is not reset after loadList is ready, thus when loadList() is called
					// again during periodically refresh then it will treat it like context has changed
					// and that caused pickupNextErrand() not working when first switched between All Errands
					// and My Errands, because handleScroll() triggered loadList() that will cancel the loadList() by PickupNext
					dispatch(setListParams({}));

					let totalSelected = getTotalSelectedErrands(getState());
					let totalSelectedErrands = getSelectedErrandIds(getState()).length;
					let totalSelectedChatErrands = getSelectedChatErrandIds(getState()).length;
					// TODO: possible improvement. See comment of
					// showMultipleActions. We can improve the code without this
					// duplicate code bottom if we manage the state properly.
					// See selectErrandFromList for similar duplication code.
					if(totalSelected > 0) {
						let ce = state.app.errand.currentErrand.id;
						if(ce > 0 || totalSelectedErrands == 0 && totalSelectedChatErrands == 0){
							dispatch(showMultipleActions(false));
						}else{
							dispatch(showMultipleActions(true));
						}
					}else{
						dispatch(showMultipleActions(false));
					}
				});
		}
	};
};

export const loadList = (origin) => rawLoadList(false, origin);

const tryReloadList = autoRefresh => (dispatch, getState) => {
	const listState = getWorkflowState(getState(), keyErrandList);
	if (!listState.err && !listState.wip) {
		return dispatch(rawLoadList(autoRefresh, "tryReloadList-"+autoRefresh));
	} else if (listState.xhr) {
		return listState.xhr;
	}
	return Promise.reject(new Error("invalid xhr for retry"));
};

export const setAgentPreference = p => async(postAgentSetPreference(p),
	workflow[keyAgentSetErrandView]
);

export const agentSetErrandView = (p) => (dispatch, getState) => {
	let view = p.preferredErrandsView;
	dispatch(changeErrandListViewMode(view));
	dispatch(setAgentPreference(p));

	//this is the other way around if should waiting until the backend is done
	// return dispatch(setAgentPreference(p))
	// .then((data) => {
	// 	let view = p.preferredErrandsView;
	// 	dispatch(changeErrandListViewMode(view));
	// });
};

export const agentSetErrandMsgTruncate = (p) => (dispatch, getState) => {
	let truncateMsg = p.errandMsgTruncate;
	dispatch(setErrandMsgTruncatedByDefault(truncateMsg));
	dispatch(setAgentPreference(p));
}

const forwardConfirmMessage = I("Are you sure you want to forward the selected errand to the area?"),
	confirmMessageToAgent = I('Do you want to send a notification to the agents working with this area?');

const checkOrSkipEmptyRecipient = (param, option, skip) => dispatch => {
	if(skip) {
		return Promise.resolve({param, option});
	}
	return dispatch(checkEmptyRecipient(param, option));
};

// NOTE: use this promise pattern whenever possible.
const checkForwardFeatureAndConfirmation = (param, option, skipEmptyReplyTo) => (dispatch, getState) => {
	const confirm = getState().app.workflow.fetchWfSettings.data['mustConfirmMoveErrand'];
	if(!confirm) {
		return Promise.resolve({param, option});
	}
	return dispatch(checkOrSkipEmptyRecipient(param, option, skipEmptyReplyTo))
		.then(({param, option}) => {
			return dispatch(enableConfirm('optional_confirm',
				forwardConfirmMessage, {option}))
				.then(() => {
					return Promise.resolve({param, option});
				});
		});
};

const checkAreaNotification = (param, option, areaId) => (dispatch, getState) => {
	return dispatch(areaNotification(areaId))
		.then(rs => {
			if(rs) {
				return dispatch(enableConfirm('optional_confirm',
					confirmMessageToAgent, {param, option}));
			} else {
				return Promise.reject();
			}
		})
		.then(() => {
			param = update(param, {send_notification: {$set: true}});
			return Promise.resolve({param, option});
		})
		.catch(() => {
			param = update(param, {send_notification: {$set: false}});
			return Promise.resolve({param, option});
		});
};

export const forwardErrandsToArea = (errandList, cipherKeys, areaId, multiple) => (dispatch, getState) => {
	if (multiple && !errandList) {
		return Promise.reject({error: "invalid errand list for forward errands to area"});
	}
	const state = getState()
		, option = update(DEF_OPT, {forwardToArea: {$set: true}})
		, external = (state.external.openedByExternal || getExtQueueType() != "")
		;
	let force
		, param
		, id
		, list
		, cipher_keys
		;
	if (multiple) {
		param = {};
		if (state.app.workflow.filter.currentContext !== CTX_MY) {
			force = true;
		}
		list = errandList;
		cipher_keys = cipherKeys;
	} else {
		id = state.app.errand.currentErrand.id;
		param = createUpdateErrandObject(null, option, state);
		list = getGroupedErrandListString(state);
		cipher_keys = "";
	}
	return dispatch(checkForwardFeatureAndConfirmation(param, option, multiple))
		.then(({param, option}) => dispatch(checkAreaNotification(param, option, areaId)))
		.then(({param, option}) => {
			param = update(param, {
				list: {$set: list},
				cipher_keys: {$set: cipher_keys},
				area: {$set: areaId},
				force: {$set: !!force},
				openedByExternal: {$set: external}
			});
			return dispatch(forwardToArea(param));
		})
		.then(() => {
			if (!multiple && id === getState().app.errand.currentErrand.id) {
				if(typeof externalqueue !== "undefined" &&
					externalqueue.isExternal == true){
					const qType = getExtQueueType();
					if (qType != SOLIDUS) {
						try {
							window.close();
						} catch (e) {
							console.log("dbg: unable to close browser window: ", e);
						}
					}
					window.location.href = "about:blank";
				}
				if (externalqueue.telavox == true) {
					dispatch(signalViewErrandOnly(false));
				}
				// TODO: do we allow pickup pickup the same errand again for
				// this case?
				dispatch(doPickupNext(0, id));
			} else {
				const { wip, err } = getState().app.workflow.forwardToArea;
				if(wip == false && typeof err !== 'undefined' && err != null){
					dispatch(togglePopAlert(err.message));
				}
				if(IsContextSearch(state.app.workflow.filter.currentContext)){
					dispatch(showMultipleActions(false));
					dispatch(handleProcessing(true));
					dispatch(handleResetOffset());
					dispatch(doGlobalSearchByWS(GLOBAL_SEARCH_FROM_BODY));

				} else {
					dispatch(loadList("forwardErrandsToArea"));
				}
			}
		})
		.catch(err => {
			if (err instanceof Error) {
				console.trace &&
					console.trace("trace forward to area error: " + err);
			}
		});
};

const areaNotification = aId => async(postAreaNotification({area: aId}), workflow[keyGetAreaNotification]);
const confirmation = (msg) => window.confirm(msg);

const updateErrandPinToTop = (p) => async(postErrandPinToTop(p),
workflow[keyErrandPinToTop]
);

export const setErrandPinToTop = (id, cipherKey, toggle) => (dispatch) => {
	dispatch(updateErrandPinToTop({id, cipherKey, toggle}))
			.then( rs =>{
				if(rs){
					if(rs.result){
						dispatch(pinErrandToTop(id, toggle));
						dispatch(loadList("setErrandPinToTop"));
					}
			}
		})
}

const setupErrandsPriority = ids => async(postErrandUpdatePriority(ids),
	workflow[keyErrandUpdatePriority]
);

export const setErrandsPriority = (ids, multiple) => (dispatch) => {
	dispatch(setupErrandsPriority(ids))
			.then( rs =>{
				if(rs){
					if(rs.status){
						if(!multiple){
							dispatch(togglePriority(ids.highPriority))
							dispatch(loadList("setErrandsPriority-notmultiple"));
						}else{
							window.alert(rs.status);
							dispatch(loadList("setErrandsPriority"));
						}
					}
			}
		})
}

const setupErrandsDueDate = p => async(postErrandUpdateDueDate(p),
	workflow[keyErrandUpdateDueDate]
);

export const setErrandsDueDate = (ids, errandId) => (dispatch) => {
	dispatch(setupErrandsDueDate(ids))
			.then( rs =>{
				if(rs){
					if(rs.data && rs.data.status){
						window.alert(rs.data.status);
						// TODO: this seem no longer relevant as we can not
						// multiple actions while an errand is opened now.
						// if (errandId > 0 ){
						// 	let params = {errand: errandId,
						// 		openedByExternal:false};
						// 	dispatch(fetchExtendedData(params));
						// }
					}
			}
		})
};

const promisePeriodic = (timeout, asyncKey, action) => dispatch => {
	const r = dispatch(action);
	dispatch(addTimeout(
		timeout
		, asyncDoneActionType(workflow[asyncKey])
		, () => dispatch(action)
	));
	return r;
};

const autoLoadList = () => tryReloadList(true);

const getTimerValue = () => {
	if (features["queued.event.raise"]) {
		return TMR_FETCH_LIST_FEATURE;
	} else {
		return TMR_FETCH_LIST;
	}
};
console.log("here is the one: ", getTimerValue())
const periodicLoadList = () => promisePeriodic(
	getTimerValue(),
	keyErrandList,
	autoLoadList()
);
export const stopPeriodicLoadList = () => removeTimeout(
	asyncDoneActionType(workflow[keyErrandList]));

const _NewIMCounters = () => (dispatch, getState) => {
	// console.log('dbg: _NewIMCounters called');
	const p5 = dispatch(getNewIMCount({autoRefresh: true}))	//New IM
	.then((r) =>{
		dispatch(NewIMCounter(r["folderCount"],'c'));
	})
	const promises = [p5];
	return Promise.all(promises);
};

const countersPollHandlers = periodicPoll(_NewIMCounters(), TMR_IM_COUNTER);

export const stopNewIMCounters = countersPollHandlers.stop;

const startNewIMCounters = countersPollHandlers.start;

export const workflowAddAgentData = (userId, loadErrandID, loadErrandHash,
	chat) => (dispatch, getState) =>{
    return dispatch(getAgentData(activeUserId))
		.then((agentData) => {
			//TODO(mujib): errandViewOnly is a global variable, which maybe in future we need to change.
			if(agentData !== null){
				dispatch(setAgentDataFromLocalDb(agentData))
				.then(() => {
					if (typeof loadErrandHash !== 'undefined' && loadErrandHash.length > 0 &&
						typeof loadErrandID !== 'undefined' && loadErrandID > 0) {
						return dispatch(fetchValidErrandId(loadErrandID, loadErrandHash))
						.then(result => {
							if (result.errandId) {
								loadErrandID = result.errandId;
							} else {
								loadErrandID = 0;
							}
						});
					}
					return;
				})
				.then(() => {
					const state = getState();
					if (state.app.workflow.fetchWfSettings.data["agent.internal-message"]) {
						if(!(typeof externalqueue !== "undefined" &&
							externalqueue.isExternal == true )) {
							dispatch(startNewIMCounters());
						}
					}
					// let openErrand;
					if (typeof loadErrandID !== 'undefined' && loadErrandID > 0) {
						if(chat != null){
							dispatch(openSingleChatErrand(loadErrandID, chat));
						} else if (errandViewOnly){
							dispatch(openSingleErrand(loadErrandID));
						} else {
							dispatch(loadAndOpenErrand(loadErrandID))
							.catch(err => {
								return;
							});
						}
					}
					if (state.app.workflow.ui.showMobileView) {
						dispatch(setErrandMobileView(false));
					}
					if(!errandViewOnly && !(typeof externalqueue !== "undefined" &&
						externalqueue.isExternal == true )){
						// add any timer to handle loadList
						dispatch(periodicLoadList());
						return;
					}
				});
				return true;
			} else {
				return false;
			}
		})
		.catch(err => {
                console.log("error found", err);
        });
}

export const workflowOnLoad = (loadErrandID, loadErrandHash, chat) => (dispatch, getState) => {
	const dispatches = [
		dispatch(onceAgents()),
		//dispatch(onceAreas()),
		dispatch(onceAgentAreas()),
		dispatch(onceConnectedAgentAreas()),
		dispatch(onceStatisticsAgentAreas()),
		dispatch(onceChannels()),
		dispatch(onceAdminTagList()),
		dispatch(onceAgentGroups()),
		dispatch(fetchAgentFavourite(AGENT_FAV_FORWARD_TO_AREA)),
		dispatch(onLoadSippConn()),
		dispatch(updateAventaLogin(true)),
		dispatch(onceKnowledgeBaseList())
	];
	return $.when(...dispatches)
		.then(() => {
			if (typeof loadErrandHash !== 'undefined' && loadErrandHash.length > 0 &&
				typeof loadErrandID !== 'undefined' && loadErrandID > 0) {
				return dispatch(fetchValidErrandId(loadErrandID, loadErrandHash))
				.then(result => {
					if (result.errandId) {
						loadErrandID = result.errandId;
					} else {
						loadErrandID = 0;
					}
				});
			}
			return;
		})
		.then(() => {
			const state = getState();
			if (state.app.workflow.fetchWfSettings.data["agent.internal-message"]) {
				if(!(typeof externalqueue !== "undefined" &&
					externalqueue.isExternal == true )) {
					dispatch(startNewIMCounters());
				}
			}
			// let openErrand;
			if (typeof loadErrandID !== 'undefined' && loadErrandID > 0) {
				if(chat != null){
					dispatch(openSingleChatErrand(loadErrandID, chat));
				} else if (errandViewOnly){
					dispatch(openSingleErrand(loadErrandID));
				} else {
					dispatch(loadAndOpenErrand(loadErrandID))
					.catch(err => {
						return;
					});
				}
			}
			if (state.app.workflow.ui.showMobileView) {
				dispatch(setErrandMobileView(false));
			}
			if(!errandViewOnly && !(typeof externalqueue !== "undefined" &&
				externalqueue.isExternal == true )){
				// add any timer to handle loadList
				dispatch(periodicLoadList());
				return;
			}
		})
		.then(() => {
			if(getExtQueueType() !== ""){
				const state = getState();
				storeAgentData(activeUserId,state);
			}
			// no need return async of this as it is not important for ready
			// flag of workflow.
		});
};

const fetchAreasFwdAgents = areaIds => async(
	postErrandAreas({ids: areaIds, fields: ["agents"]})
	, workflow[keyAreasAgentsData]
);

export const fetchAreaForwardAgents = areaIds => (dispatch, getState) => {
	if (process.env.NODE_ENV !== 'production') {
		console.log("dbg: open forward to agent:", {areaIds});
	}
	if (!areaIds) {
		return;
	}
	const { domain } = getState()
		, ids = []
		;
	$.each(areaIds.split(","), (i, v) => {
		if (!getNormalizedDomain(domain, D_FORWARD_AGENTS, v)) {
			ids.push(str2Int(v));
		}
	});
	if (!ids.length) {
		if (process.env.NODE_ENV !== 'production') {
			console.log("dbg: all areas forward agents available.");
		}
		return;
	}
	if (process.env.NODE_ENV !== 'production') {
		console.log("dbg: prepare fetch area forward agents:", {ids});
	}
	return dispatch(fetchAreasFwdAgents(ids));
};

export const changeAreaFilter = (context, areasObject) => dispatch => {
	dispatch(changeSelectedFilteredAreas(context, areasObject));
	return dispatch(loadList("changeAreaFilter"));
};

export const integrationCreateManualErrand = p =>
	async(getCreateManualErrand(p), workflow[keyCreateManualErrand]);

export const fetchAllIncomingEmailErrands = (limit, offset) =>
	async(getIncomingEmailErrands({limit, offset}), workflow[keyIncomingEmailErrands]);

export const fetchAllActiveErrands = () =>
	async(getAllActiveErrands(), workflow[keyAllActiveErrands]);

export const fetchAllActiveAgents = () =>
	async(getAllActiveAgents(), workflow[keyAllActiveAgents]);

export const getErrandsDueInAWeek = () =>
	async(fetchDueErrandsInAWeek(), workflow[keyFetchDueErrandsInAWeek]);

export const deleteLaunchpadWidgetAction = (id) =>
	async(deleteLaunchpadWidget(id), workflow[keyDeleteLaunchpadWidgets]);

export const addLaunchpadWidgetAction = (param) =>
	async(addNewLaunchpadWidget(param), workflow[keyAddLaunchpadWidgets]);

export const periodicDueList = () => promisePeriodic(
	TMR_FETCH_LAUNCHPAD
	, keyFetchDueErrandsInAWeek
	, getErrandsDueInAWeek()
);

export const stopPeriodicDueList = () => removeTimeout(
	asyncDoneActionType(workflow[keyFetchDueErrandsInAWeek]));

export const getIncomingCollabs = () =>
	async(fetchIncomingCollaborations(), workflow[keyFetchIncomingCollaborations]);

export const periodicCollabsList = () => promisePeriodic(
	TMR_FETCH_LAUNCHPAD
	, keyFetchIncomingCollaborations
	, getIncomingCollabs()
);

export const stopPeriodicCollabsList = () => removeTimeout(
	asyncDoneActionType(workflow[keyFetchIncomingCollaborations]));

export const getForwardedErrands = () =>
	async(fetchForwardedErrands(), workflow[keyFetchForwardedErrands]);

export const periodicForwardedList = () => promisePeriodic(
	TMR_FETCH_LAUNCHPAD
	, keyFetchForwardedErrands
	, getForwardedErrands()
);

export const stopPeriodicForwardedList = () => removeTimeout(
	asyncDoneActionType(workflow[keyFetchForwardedErrands]));

export const getExpiringErrands = () =>
	async(fetchExpiringErrands(), workflow[keyFetchExpiringErrands]);

export const periodicExpiringList = () => promisePeriodic(
	TMR_FETCH_LAUNCHPAD
	, keyFetchExpiringErrands
	, getExpiringErrands()
);

export const stopPeriodicExpiringList = () => removeTimeout(
	asyncDoneActionType(workflow[keyFetchExpiringErrands]));

export const fetchQueueBasicErrandInfo = (queueId) => {
	// console.log("dbg sue: fetching basic errand info for this queued errand ? ", queueId);
	return async(postOneBasicErrand(queueId), workflow[keyQueueBasicErrandInfo]);
}
