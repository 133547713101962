// ajax.js is just a shortcut function name hiding the RESTful function. Its
// function name should closely representing the REST API method and endpoint.
import pathToRegexp from 'path-to-regexp';
import RESTful, { CentionREST, DurlREST } from './restful';
import SocketAPI, { CallAgentAPI, ChatAgentAPI } from './socketAPI';
import {
	AdminAWSSESIdentities,
	AdminAgentsAccessKeys,
	ContactcardsList,
	ContactcardsEntryList,
	AdminFilesAreaarchiveimages,
	AdminChatbots,
	AdminChatbotsIntents,
	AdminChatbotsTrainingphrases,
	AdminFilesUnzipfeedbacktemplate,
	AdminGlobalAddressbookEdit,
	AdminGlobalAddressbookGet,
	AdminGlobalAddressbookList,
	AdminGlobalAddressbookSave,
	AdminGlobalAddressbookDelete,
	AdminGlobalAddressbookUpload,
	AdminErrandInternalState,
	AdminAccountFacebook,
	AdminAccountFacebookSave,
	AdminFileArchive,
	AdminFileArchiveEdit,
	AdminFileArchiveSave,
	AdminFileArchiveDownload,
	AdminGenerativeAIDocument,
	AdminReceiptgreetings,
	AdminReceiptgreetingsFeedbacksResponseimages,
	AdminReceiptgreetingsFeedbacksResponsetemplates,
	AdminServiceaccounts,
	AdminSmtp,
	RouteKeywords,
	RoutingGroups,
	AdminGroups,
	GroupFolders,
	GroupAgents,
	CORSWhitelist,
	CallRecordings,
	AgentSipLogin,
	SipPriorityNumbers,
	ContactCards,
	SaveContactCards,
	MergeContactCards,
	RouteAutoTags,
	RouteSip,
	AdminLeaderboard,
	AdminPinReports,
	AdminReports,
	AdminReportsExport,
	AdminReportsExportV5,
	AdminReportsOverviewLayout,
	AdminTagList,
	AdminTagUpload,
	AdminTagRemove,
	AdminTagSave,
	AdminTagAppend,
	AdminTagNameAll,
	AdminTagSimpleList,
	AgentAreas,
	AgentTemplates,
	AgentCurrentStatus,
	AgentSetPreference,
	AgentGetSelectedStatus,
	AgentLastSeen,
	AgentPing,
	Agents,
	Folders,
	Areas,
	OrganizationOverviewAreas,
	Channels,
	CollaborationAnswers,
	CollaborationQueryRecipients,
	Constants,
	ErrandAcquire,
	ErrandAgentStatistics,
	ErrandAreaData,
	ErrandAreas,
	ErrandBasic,
	ErrandMinLoad,
	ErrandChangeErrandAgent,
	ErrandChangeErrandArea,
	ErrandChangeErrandFolder,
	ErrandCloseErrand,
	ErrandCloseErrands,
	ErrandContacts,
	ErrandMyErrands,
	ErrandFetchLinkedErrands,
	ErrandDeleteErrands,
	ErrandExtendedData,
	ErrandFetchAreaErrandCount,
	ErrandFetchWorkflowSettings,
	ErrandFetchWorkflowLimitedSettings,
	ErrandFetchClientAddress,
	ErrandFetchClientsAddressList,
	ErrandHeaderSettings,
	ErrandHistory,
	ErrandKnowledgeBase,
	ErrandKnowledgeBaseList,
	ErrandKnowledgeBaseFeaturedQuestion,
	ErrandList,
	ErrandNotes,
	ErrandSuggestedAnswer,
	ErrandNotesDeleteAttachment,
	ErrandNotesUploadAttachment,
	ErrandPassiveChangeErrandArea,
	ErrandChangeInternalState,
	ErrandSendInternalStateChange,
	ErrandQueueErrandToUser,
	ErrandTokenDecrypt,
	ErrandPreview,
	ErrandRemoveTempAttachment,
	ErrandReopenErrand,
	ErrandResendErrand,
	ErrandPublishErrand,
	ErrandReturnErrands,
	LinkErrand,
	TwoFANewSecret,
	VerifyTwoFAToken,
	ErrandSendErrand,
	SendBulkErrands,
	ErrandUnpublishErrand,
	ErrandUpdateErrand,
	ErrandUploadAnswerAttachment,
	ExternalexpertAddressList,
	ExternalexpertAddressSave,
	ExternalexpertAddressRemove,
	ExternalexpertAddressRemoveAvatar,
	ExternalexpertAnswerSave,
	ExternalexpertEdit,
	ExternalexpertFetchMessage,
	ExternalexpertLightWithAction,
	ExternalexpertList,
	ExternalExpertQueries,
	ExternalexpertSend,
	ExternalexpertUpdate,
	ExternalexpertLastSave,
	ExternalDoPaging,
	ExtQueueDoneErrand,
	File,
	Groups,
	// OverviewActiveAgents,
	// OverviewFbPageRatings,
	// OverviewOrganisations,
	QueueToMe,
	ReviewAddresses,
	ReviewAgents,
	ReviewAddressesFile,
	Salutation,
	SalutationEdit,
	SalutationSave,
	SalutationDelete,
	Signature,
	SignatureEdit,
	SignatureSave,
	SignatureDelete,
	SmppconfigurationEdit,
	SmppconfigurationList,
	SocketAgentApiAcceptchat,
	SocketAgentApiChatsessioncount,
	SocketAgentCallApiAccept,
	SocketAgentCallApiOutboundPhones,
	Systemgroup,
	SystemgroupEdit,
	SystemgroupList,
	SystemgroupSave,
	SystemgroupSwitch,
	Translate,
	TranslateDetect,
	Templates,
	TemplateDelete,
	TemplateEdit,
	TemplateSave,
	TimeTimespan,
	TimeTimespanItems,
	TimeTimeformat,
	TimeTimezones,
	Wordlist,
	WordlistEdit,
	WordlistSave,
	WordlistDelete,
	ExportList,
	ExportCallList,
	ExportCalls,
	SimpleTagListByArea,
	CreateExport,
	SaveExport,
	RemoveExport,
	ActivateExport,
	GeneratedExport,
	RemoveGeneratedExport,
	FetchExportFile,
	GeneratedCdrExport,
	GeneratedPreview,
	GetAreaNotification,
	ErrandPrintContent,
	InternalMessage,
	FavouriteChatAgent,
	Agentlist, // SOCKET start
	Answerinvite,
	Forward,
	GetAreas,
	GetAgents,
	Queues,
	CanClose,
	Sessiondata,
	AcceptInternalChat,
	ErrandPinToTop,
	ErrandUpdatePriority,
	ErrandFetchAreaTags,
	ErrandFBRatings,
	ErrandUpdateDueDate,
	ErrandDoPaging,
	ErrandStartCloseCountdown,
	OutboundApiCall,
	HangupApiCall,
	CommonCallApi,
	CommonSnoopApi,
	AgentFwdCallToExt,
	SipManualCallStatus,
	SipIsSipAvailable,
	ChatIPAddressBlacklist,
	CustomerByAddress,
	AddEntryToContactCard,
	DeleteEntryFromContactCard,
	GetCustomerNotes,
	DeleteCustomerNote,
	AddCustomerNote,
	GetContactBook,
	GetAnnoucement,
	ErrandStatus,
	ChatErrandStatus,
	GetClientAvatar,
	PostCustomerAvatar,
	PostCustomerDelAvatar,
	AgentChatSourceConfig,
	AgentFavourite,
	AgentAventaLogin,
	GetAvailableSipAgents,
	GetAllSipAgents,
	GetActiveCallLogs,
	GetSipNumberInUse,
	Anonymize,
	ExportLog,
	ContactExport,
	AgentcardsEntryList,
	GetAgentBook,
	GetGroupBook,
	IMSendMessage,
	IMCounter,
	IMDeleteMessages,
	IMMessageRead,
	IMSaveDraft,
	GetAgentGroups,
	Membershipd,
	NewIMCount,
	IMRestoreMessages,
	RecordEditorSize,
	ScheduleReport,
	ShareReport,
	SavedReport,
	ReportKeys,
	ReportGroups,
	ReportPreview,
	LastErrandInThread,
	FetchErrandId,
	ErrandUpdateLockToMe,
	ErrandFBEmotions,
	SetErrandPostponeDate,
	AgentAdmin,
	AgentEdit,
	AgentList,
	AgentNew,
	AgentActivate,
	AgentDeactivate,
	AgentGetSalutations,
	AgentGetSignatures,
	AgentAddMP,
	AgentRemoveMP,
	AgentUploadAvatar,
	AgentRemoveAvatar,
	AgentUploadList,
	AutoTagUploadList,
	AgentImportStatus,
	AgentValidate,
	AgentUnlock,
	InternalMessageHistory,
	AgentValidateExternalID,
	AgentCallLog,
	GetErrandData,
	AdminLibrary,
	AdminLibraryCategory,
	AdminLibraryCategoryRemove,
	AdminLibraryQuestion,
	AdminLibraryQuestionRemove,
	AdminLibraryRating,
	AdminLibraryRatingRemove,
	AdminLibraryRemove,
	AdminLibrarySuggestion,
	AdminLibraryTreeInsert,
	AdminLibraryTreeRemove,
	AdminLibraryTreeRoots,
	ErrandKnowledgeBaseCategory,
	VerifySendingByChannel,
	GetErrandSlaTime,
	ErrandKnowledgeBaseUserVote,
	AdminLibraryList,
	AdminLibraryCategoryList,
	AdminLibraryQuestionList,
	AdminLibrarySuggestionList,
	AdminLibraryRatingList,
	GetErrandAcquireOwnerStatus,
	AgentWorkingOnErrand,
	ActiveErrandList,
	ActiveAgents,
	IncomingEmailErrands,
	CompanyList,
	ConfigChangeLog,
	CompanyNew,
	CompanyRemove,
	CollaborationQuerySender,
	ChatWidgetCfgNew,
	ChatWidgetCfgList,
	ChatWidgetCfgDefList,
	ChatWidgetCfgDelete,
	FaqWidgetCfgNew,
	FaqWidgetCfgList,
	FaqWidgetCfgDefList,
	FaqWidgetCfgDelete,
	VoiceWidgetCfgNew,
	VoiceWidgetCfgList,
	VoiceWidgetCfgDefList,
	VoiceWidgetCfgDelete,
	ClosedChatAPI,
	SocketAgentApiChatStatus,
	ConnectedAgentAreas,
	StatisticsAgentAreas,
	AdminAgentStatusList,
	AdminAgentSipStatus,
	AdminStunTurn,
	AdminStunTurnStatus,
	AdminAgentSipStatusValidate,
	AdminClassifier,
	IsAgentLoggedIn,
	CreateManualErrand,
	TagKeywords,
	QuickReplies,
	QuickReplyEdit,
	QuickReplyDelete,
	QuickReplySave,
	CRMSystemData,
	AdminOrganisation,
	AgentEmailAvailable,
	AdminGetOrganisationById,
	AdminCallbackAPI,
	AdminJWTAPI,
	CallAdminIVR,
	CallAdminIVRPromptUpload,
	CallAdminIVRMOHUpload,
	CallAdminIVRDeploy,
	CallAdminSipTrunk,
	ErrandRewriteAnswerBase,
	FetchForwardedErrands,
	FetchIncomingCollaborations,
	FetchDueErrandsInAWeek,
	FetchExpiringErrands,
	DeleteLaunchpadWidget,
	AddNewLaunchpadWidget,
	SkillsCategory,
	Skills,
	SkillProf,
	SkillAgent,
	SkillArea,
	GetSummarized,
	GetEmailAndThreadSummary,
	AgentAssist,
	AdminLanguageList,
	Blacklist,
	BlacklistResp,
	Whitelist,
	ToolManager,
} from '../../constants/endpoints';

function constantSuffixEndpointFactory(suffix) {
	return function (endpoint, id) {
		return endpoint + "/" + id + suffix;
	};
}

function urlAccountEnabler(endpoint, p) {
	return endpoint + `/area/${p.area}/acid/${p.id}/deactivate`;
}

const reportDrilldownEndpointFactory = constantSuffixEndpointFactory

function reportDrilldownRESTCreator(suffix) {
	const endpointCreator = reportDrilldownEndpointFactory(suffix);
	return query => RESTful(AdminReports, query, endpointCreator);
}

const reportDrilldownREST = reportDrilldownRESTCreator("/drilldown");

export const reportDrilldownExportREST = reportDrilldownRESTCreator("/drilldown/export");

const pathToRegexpUrl = (endpoint, id) => pathToRegexp.compile(endpoint)(id)

const createAdminChatbotsIntentsUrl = pathToRegexpUrl

const createAdminReceiptgreetingsUrl = pathToRegexpUrl

const createAdminSmtpUrl = pathToRegexpUrl

export const {
	get: getAdminAgentsAccessKeys,
	post: postAdminAgentsAccessKeys,
	delete: deleteAdminAgentsAccessKeys,
	url: adminAgentAccessKeyURL
} = DurlREST(AdminAgentsAccessKeys);

export const {
	get: getAWSSESIdentities,
	post: postAWSSESIdentities,
	delete: deleteAWSSESIdentities
} = DurlREST(AdminAWSSESIdentities);

// GET
export const getAcceptCall = p => () =>
	CallAgentAPI(SocketAgentCallApiAccept, p).get();
export const getAdminFilesAreaarchiveimages = () => () => RESTful(AdminFilesAreaarchiveimages).get();
export const getAdminChatbots = () => () => RESTful(AdminChatbots).get();
export const getAdminChatbotsIntents = id => () => RESTful(
	AdminChatbotsIntents,
	undefined,
	createAdminChatbotsIntentsUrl
).get(id);
export const getAdminLeaderboard = q => () =>
	RESTful(AdminLeaderboard, q).get();
export const getAdminPinReports = () => () => RESTful(AdminPinReports).get();
export const getAdminReceiptgreetings = () => () => RESTful(
	AdminReceiptgreetings,
	undefined,
	createAdminReceiptgreetingsUrl
).get();
export const getAdminReports = (p) => () => RESTful(AdminReports, p).get();
export const getAdminReportsOverviewLayout = () => () => RESTful(AdminReportsOverviewLayout).get();
export const getAdminReportsExport = (id, q) => () => {
	return RESTful(AdminReportsExport, q).get(id)
};
export const getAdminReportsExportV5 = (id, q) => () => {
	return RESTful(AdminReportsExportV5, q).get(id)
};
export const getAdminServiceaccounts = q => () => RESTful(
	AdminServiceaccounts,
	q
).get();
export const getAdminSmtp = () => () => RESTful(
	AdminSmtp,
	undefined,
	createAdminSmtpUrl
).get();
export const getAdminTagSimpleList = q => () => {
	return RESTful(AdminTagSimpleList, q).get();
};
export const getAdminTagList = q => () => {
	return RESTful(AdminTagList, q).get();
};
export const getAdminTagRemove = q => () => {
	return RESTful(AdminTagRemove, q).get();
};
export const postAdminTagSave = p => () => {
	return RESTful(AdminTagSave, p).post();
};
export const postAdminTagAppend = (p) => () => {
	return RESTful(AdminTagAppend, p).post();
};
export const postAdminTagUpload = p => () => {
	return RESTful(AdminTagUpload, p).postMultipart();
};
export const getAgents = q => () => {
	return RESTful(Agents, q).get();
};
export const getFolders = q => () => {
	return RESTful(Folders, q).get();
};
export const getChannels = () => () => {
	return RESTful(Channels).get();
};
export const getAgentAreas = q => () => {
	return RESTful(AgentAreas, q).get();
};
export const getAgentTemplates = () => () => {
	return RESTful(AgentTemplates).get();
}
export const getAgentLastSeen = q => () => {
	return RESTful(AgentLastSeen, q).get();
};
export const getAgentPing = () => () => {
	return RESTful(AgentPing).get();
};
export const getAgentLoggedStatus = () => () => {
	return RESTful(IsAgentLoggedIn).get();
};
export const getAreas = q => () => {
	return RESTful(Areas, q).get();
};
export const getOrganizationOverviewAreas = q => () => {
	return RESTful(OrganizationOverviewAreas, q).get();
}
export const getChatSessionCount = () => () => {
	return ChatAgentAPI(SocketAgentApiChatsessioncount).get();
};
export const getConstants = name => () => RESTful(
	Constants,
	undefined,
	pathToRegexpUrl
).get(name);
export const getErrandFetchAreaErrandCount = q => () => {
	return RESTful(ErrandFetchAreaErrandCount, q).get();
};
export const getErrandAgentStatistics = q => () => {
	return RESTful(ErrandAgentStatistics, q).get();
};
export const getExternalexpertEdit = q => () => RESTful(ExternalexpertEdit, q).get();
export const getExternalexpertLightWithAction = q => () => {
	return RESTful(ExternalexpertLightWithAction, q).get();
};
export const getExternalexpertList = q => () => {
	return RESTful(ExternalexpertList, q).get();
};
export const getExternalexpertThread = q => () => {
	return RESTful(ExternalexpertFetchMessage, q).get();
};
export const getExternalExpertQueries = q => () => {
	return RESTful(ExternalExpertQueries, q).get();
};
export const getErrandNotes = q => () => {
	return RESTful(ErrandNotes, q).get();
};
export const getConnectedAgentAreas = q => () => {
	return RESTful(ConnectedAgentAreas, q).get();
};
export const getStatisticsAgentAreas = q => () => {
	return RESTful(StatisticsAgentAreas, q).get();
};
export const getErrandSuggestedAnswer = id => () => RESTful(ErrandSuggestedAnswer).get(id);

export const getGroups = () => () => RESTful(Groups).get();
export const getOutboundPhones = () => () => CallAgentAPI(SocketAgentCallApiOutboundPhones).get();
export const getReviewAddresses = q => getOneReviewAddress(undefined, q);
export const getReviewAgents = q => () => RESTful(ReviewAgents, q).get();
export const getSalutations = q => () => RESTful(Salutation, q).get();
export const getSignatures = q => () => RESTful(Signature, q).get();
export const getTemplates = q => () => RESTful(Templates).get(q);
export const getQuickReplies = q => () => RESTful(QuickReplies).get(q);
export const getTimeTimespan = () => () => RESTful(TimeTimespan).get();
export const getTimeTimespanItems = () => () => RESTful(TimeTimespanItems).get();
export const getTimeformat = () => () => RESTful(TimeTimeformat).get();
export const getTimezones = () => () => RESTful(TimeTimezones).get();
export const getExportList = () => () => RESTful(ExportList).post();
export const getExportCallList = () => () => RESTful(ExportCallList).post();
export const getSimpleTagListByArea = q => () => RESTful(SimpleTagListByArea, q).get();
export const getKnowledgeBase = (id,param) => () => {
	return RESTful(ErrandKnowledgeBase,param).get(id);
};
export const getKnowledgeBaseList = q => () => {
	return RESTful(ErrandKnowledgeBaseList,q).get();
};
export const getVoteFeaturedQuestion = id => () => {
	return RESTful(ErrandKnowledgeBaseFeaturedQuestion).get(id);
}
export const postVoteFeaturedQuestion = id => () => {
	return RESTful(ErrandKnowledgeBaseFeaturedQuestion, id).post();
}
export const getUserVote = id => () => {
	return RESTful(ErrandKnowledgeBaseUserVote).get(id);
}
export const postUserVote = id => () => {
	return RESTful(ErrandKnowledgeBaseUserVote, id).post();
}
export const generateAIAnswerQues = (q) => () => {
	const val = JSON.stringify({area: q.area, content: q.content, context: q.context, langCode: q.langCode, newHistory: q.newHistory, errandId: q.errandId, sessionId: q.sessionId});
	return RESTful(ErrandRewriteAnswerBase, val).post();
}
export const getCustomerNotes = q => () => {
	return RESTful(GetCustomerNotes,q).get();
};
export const getContactBook = q => () => {
	return RESTful(GetContactBook,q).get();
};

export const getAnnouncement = id => () => RESTful(GetAnnoucement).get(id);

export const verifyChannelSelection = q => () => {
	return RESTful(VerifySendingByChannel, q).get();
};
export const getReportKeys = () => () => RESTful(ReportKeys).get();
export const getReportGroups = () => () => RESTful(ReportGroups).get();

// GET one
export const getAdminOneChatbot = id => () => RESTful(AdminChatbots).get(id);
export const getOneAdminPinReports = id => () =>
	RESTful(AdminPinReports).get(id);
export const getOneCollaborationQueryRecipients = id => () => RESTful(
	CollaborationQueryRecipients).get(id);
export const postCheckEEToBeSent = addr => () => RESTful(
	CollaborationQuerySender, addr).post();
export const getSlaTime = id => () => RESTful(GetErrandSlaTime).get(id);
export const getOneErrandContacts = (id, q) => () => RESTful(ErrandContacts, q)
	.get(id);
export const getErrandMyErrands = (id, q) => () => RESTful(ErrandMyErrands, q)
	.get(id);
export const getErrandLinkedErrands = (id, q) => () => RESTful(ErrandFetchLinkedErrands, q)
	.get(id);


export const getOneErrandNotes = (id, q) => () => RESTful(ErrandNotes, q)
	.get(id);
export const getOneChatbotIntent = id => () => RESTful(ReviewAddresses, q).get(id);
export const getOneSalutation = (q) => () => RESTful(SalutationEdit, q).get();
export const getOneErrandInternalState = (id) => () => RESTful(AdminErrandInternalState).get(id);
export const getOneSignature = (q) => () => RESTful(SignatureEdit, q).get();
export const getOneTemplate = q => () => RESTful(TemplateEdit, q).get();
export const getOneQuickReply = q => () => RESTful(QuickReplyEdit, q).get();
export const getOneQuickReplyDelete = id => () => RESTful(QuickReplyDelete).get(id);
export const getOneTemplateDelete = id => () => RESTful(TemplateDelete).get(id);
export const getGeneratedExport = id => () => RESTful(GeneratedExport).get(id);
export const getGeneratedCdrExport = id => () => RESTful(GeneratedCdrExport).get(id);
export const getContactCardEntry = id => () => RESTful(ContactcardsEntryList).get(id);
export const getCustomerByAddress = q => () => RESTful(CustomerByAddress, q).get();
export const getErrandStatus = id => () => RESTful(ErrandStatus).get(id);
export const getSavedReport = id => () => RESTful(SavedReport).get(id);
export const getOneTimeSpan = id => () => RESTful(TimeTimespan).get(id);
export const getLastErrandInThread = id => () => RESTful(LastErrandInThread).get(id);
export const getErrandId = q => () => RESTful(FetchErrandId, q).get();
export const getOpenErrandData = (id, q) => () => RESTful(GetErrandData, q).get(id);
export const getOneReviewAddress = (id, q) => () => RESTful(ReviewAddresses, q).get(id);
export const getOneFileArchive = p => () => RESTful(AdminFileArchive, p).get();
export const getErrandMinLoad = (id, q) => () => RESTful(ErrandMinLoad, q).get(id);

// POST
export const postAcceptCall = p => () =>
	CallAgentAPI(SocketAgentCallApiAccept, p).post();
export const postAcceptChat = p => () => ChatAgentAPI(SocketAgentApiAcceptchat, p)
	.post();
export const postAdminChatbots = (id, p) => () => RESTful(
	AdminChatbots,
	p
).postJSON(id);
export const postAdminChatbotsTrainingphrases = p => () => RESTful(
	AdminChatbotsTrainingphrases,
	p
).postJSON();
export const postAdminChatbotsQuery = (id, p) => () => RESTful(
	AdminChatbots,
	p,
	constantSuffixEndpointFactory("/query")
).postJSON(id);
export const postAdminChatbotsSync = (id, p) => () => RESTful(
	AdminChatbots,
	p,
	constantSuffixEndpointFactory("/sync")
).postJSON(id);
export const postAdminChatbotsIntents = (id, p) => () => RESTful(
	AdminChatbotsIntents,
	p,
	createAdminChatbotsIntentsUrl
).postJSON(id);
export const postAdminFilesUnzipfeedbacktemplate = p => () => RESTful(
	AdminFilesUnzipfeedbacktemplate,
	p
).postJSON();
export const postAdminReceiptgreetings = (id, p) => () => RESTful(
	AdminReceiptgreetings,
	p,
	createAdminReceiptgreetingsUrl
).postJSON(id);
export const postAdminReceiptgreetingsFeedbacksResponseimages = p => RESTful(
	AdminReceiptgreetingsFeedbacksResponseimages,
	p,
).postMultipart();
export const postAdminReceiptgreetingsFeedbacksResponsetemplates = p => RESTful(
	AdminReceiptgreetingsFeedbacksResponsetemplates,
	p
).postMultipart();
export const postAdminSmtp = (id, p) => () => RESTful(
	AdminSmtp,
	p,
	createAdminSmtpUrl
).postJSON(id);
export const postAdminTagNameAll = p => () => RESTful(AdminTagNameAll, p)
	.postJSON();
export const postAdminReportsOverviewLayout = p => () => RESTful(AdminReportsOverviewLayout, p).postJSON();
export const postAgentCurrentStatus = p => () => RESTful(AgentCurrentStatus, p)
	.post();
export const postAgentGetSelectedStatus = p => () => RESTful(
	AgentGetSelectedStatus, p).post();
export const postAgentSetPreference = p => () => RESTful(
	AgentSetPreference, p).post();
export const postAdminPinReports = p => () =>
	RESTful(AdminPinReports, p).post();
export const postOneReviewAddress = (id, p) => () => RESTful(ReviewAddresses, p).postJSON(id);
export const postReviewAddress = p => postOneReviewAddress(undefined, p);
export const postReviewByAgent = p => () => RESTful(ReviewAgents, p).postJSON();
export const postReviewAddressesFile = p => () => RESTful(ReviewAddressesFile, p).postJSON();
export const postScheduleReport = p => () =>
	RESTful(ScheduleReport, p).post();
export const getScheduleReport = p => () =>
	RESTful(ScheduleReport).get();
export const postShareReport = p => () => RESTful(
	ShareReport, p).post();
export const postReportPreview = (p) => () => RESTful(ReportPreview, p).post();
export const postErrandInternalState = (p) => () => RESTful(AdminErrandInternalState, p).post();
export const postTimeSpan = (p) => () => RESTful(TimeTimespan, p).post();

export const postAgentCallLog = (p) => () => RESTful(AgentCallLog, p).post();

export const putExportCall = (id, p) => () => RESTful(ExportCalls, p).put(id);
export const deleteExportCall = (p) => () => RESTful(ExportCalls).delete(p);
export const patchActivateExportCall = (id, p) => () => RESTful(ExportCalls, p).patch(id);

//Delete
export const deleteAdminChatbots = id => () => RESTful(AdminChatbots).delete(id);
export const deleteAdminChatbotsIntents = id => () =>  RESTful(
	AdminChatbotsIntents,
	undefined,
	createAdminChatbotsIntentsUrl
).delete(id);
export const deleteAdminReceiptgreetings = id => () => RESTful(
	AdminReceiptgreetings,
	undefined,
	createAdminReceiptgreetingsUrl
).delete(id);
export const deleteScheduleReport = p => () =>
	RESTful(ScheduleReport).delete(p);
export const deleteAdminSmtp = id => () => RESTful(
	AdminSmtp,
	undefined,
	createAdminSmtpUrl
).delete(id);
export const deleteCustomReport = p => () =>
	RESTful(AdminReports).delete(p);
export const deleteTimeConfig = p => () =>
	RESTful(TimeTimespan).delete(p);
export const deleteReviewAddress = id => () =>
	RESTful(ReviewAddresses).delete(id);
export const deleteReviewAgent = id => () =>
	RESTful(ReviewAgents).delete(id);
export const deleteSignature = p => () =>
	RESTful(SignatureDelete).get(p);
export const deleteSalutation = p => () =>
	RESTful(SalutationDelete).get(p);

// Internal chat
export const getFavouriteChatAgent = p => () => RESTful(FavouriteChatAgent, p).get();
export const postFavouriteChatAgent = p => () => RESTful(FavouriteChatAgent, p).post();
// Chat agent.api
export const postAcceptInternalChat = p => () => ChatAgentAPI(AcceptInternalChat, p).post();
export const postChatAgentAPIAgentlist = p => () => ChatAgentAPI(Agentlist, p).post();
export const getChatAgentAPIAnswerinvite = p => () => ChatAgentAPI(Answerinvite, p).get();
export const getChatAgentAPIQueues = p => () => ChatAgentAPI(Queues, p).get();
export const getClosedChatErrand = p => () => ChatAgentAPI(ClosedChatAPI, p).get();
export const getChatAgentAPIStatus = p => () => ChatAgentAPI(SocketAgentApiChatStatus, p).get();
// todo
// export const getChatAgentAPIForward = p => () => ChatAgentAPI(Forward, p).get();
// export const getChatAgentAPIGetAreas = p => () => ChatAgentAPI(GetAreas, p).get();
// export const getChatAgentAPIGetAgents = () => () => ChatAgentAPI(GetAgents, p).get();
export const getChatAgentAPICanClose = p => () => SocketAPI(CanClose, p).get();
export const postChatAgentAPISessiondata = p => () => ChatAgentAPI(Sessiondata, p).post();

export const postCloseErrand = p => () => {
	return RESTful(ErrandCloseErrand, p).post();
};
export const postCloseErrands = p => () => {
	return RESTful(ErrandCloseErrands, p).post();
};
export const postBulkSendErrands = p => () => {
	return RESTful(SendBulkErrands, p).post();
};
export const postCollaborationAnswers = p => () => {
	return RESTful(CollaborationAnswers, p).post();
};
export const postDeleteErrands = p => () => {
	return RESTful(ErrandDeleteErrands, p).post();
};
export const postErrandAcquire = p => () => {
	return RESTful(ErrandAcquire, p).post();
};
export const postErrandAreas = p => () => {
	return RESTful(ErrandAreas, p).postJSON();
};
// TODO: switch to GET one
export const postErrandAreaData = p => () => {
	return RESTful(ErrandAreaData, p).post();
};
export const postErrandChangeErrandArea = p => () => {
	return RESTful(ErrandChangeErrandArea, p).post();
};
export const postErrandChangeErrandAgent = p => () => {
	return RESTful(ErrandChangeErrandAgent, p).post();
};
export const postErrandChangeErrandFolder = p => () => {
	return RESTful(ErrandChangeErrandFolder, p).post();
};
export const postErrandExtendedData = p => () => {
	return RESTful(ErrandExtendedData, p).post();
};
export const postErrandFetchClientAddress = p => () => {
	return RESTful(ErrandFetchClientAddress, p).post();
};
export const postErrandFetchClientsAddressList = p => () => {
	return RESTful(ErrandFetchClientsAddressList, p).post();
};
export const postErrandHeaderSettings = p => () => {
	return RESTful(ErrandHeaderSettings, p).post();
};
export const postErrandHistory = p => () => {
	return RESTful(ErrandHistory, p).post();
};
// WARNING: dangerous to change to GET as current POST utility heavily on body
// form parameters which has quite big bytes. Changing this body bytes to URL
// GET query parameters (as normal practise) may break as webserver can impose
// limitation on how long the URL can be.
export const postErrandList = p => () => {
	return RESTful(ErrandList, p).post();
};
export const getEmailAndThreadSummary = (threadId) => {
    const queryParams = { threadId };
    return RESTful(GetEmailAndThreadSummary, queryParams).get();
};

export const getSummaryHistory = p => () => RESTful(GetSummarized, p).post();
export const postErrandNotes = p => () => RESTful(ErrandNotes, p).post();
export const postErrandNotesDeleteAttachment = p => () => RESTful(
	ErrandNotesDeleteAttachment, p).post();
export const postErrandNotesUploadAttachment = p => () => RESTful(
	ErrandNotesUploadAttachment, p).postMultipart();
export const postErrandPassiveChangeErrandArea = p => () => RESTful(
	ErrandPassiveChangeErrandArea, p).post();
export const postErrandChangeInternalState = p => () => RESTful(
	ErrandChangeInternalState, p).post();
export const postErrandSendInternalStateChange = p => () => RESTful(
	ErrandSendInternalStateChange, p).post();
export const postErrandQueueErrandToUser = p => () => {
	return RESTful(ErrandQueueErrandToUser, p).post();
};
export const postErrandTokenDecrypt = p => () => {
	return RESTful(ErrandTokenDecrypt, p).post();
};
export const postErrandRemoveTemporaryAttachment = p => () => {
	return RESTful(ErrandRemoveTempAttachment, p).post();
};
export const postErrandReopenErrand = p => () => RESTful(ErrandReopenErrand,
	p).post();
export const postErrandResendErrand = p => () => RESTful(ErrandResendErrand,
	p).post();
export const postErrandPublishErrand = p => () => RESTful(ErrandPublishErrand,
	p).post();
export const postErrandReturnErrands = p => () => RESTful(ErrandReturnErrands,
	p).post();
export const postLinkErrand = p => () => RESTful(LinkErrand,
		p).post();
export const postErrandSendErrand = p => () => RESTful(ErrandSendErrand,
	p).post();
export const postErrandDoPaging = (p) => () => RESTful(ErrandDoPaging, p).post();
export const postErrandStartCountdown = (p) => () => RESTful(ErrandStartCloseCountdown, p).post();
export const postOutboundApiCall = (p) => () => RESTful(OutboundApiCall, p).post();
export const postHangupApiCall = (p) => () => RESTful(HangupApiCall, p).post();
export const postCommonCallApi = (p) => () => RESTful(CommonCallApi, p).post();
export const postCommonSnoop = (p) => () => RESTful(CommonSnoopApi, p).post();
export const postAgentFwdCallToExt = (p) => () => RESTful(AgentFwdCallToExt, p).post();
export const postSipManualCallStatus = (p) => () => RESTful(SipManualCallStatus, p).post();
export const getSipIsSipAvailable = (p) => () => RESTful(SipIsSipAvailable, p).get();
export const postErrandUpdateErrand = p => () => RESTful(ErrandUpdateErrand,
	p).post();
export const postExtQueueDoneErrand = p => () => RESTful(ExtQueueDoneErrand,
	p).post();
export const postErrandSuggestErrandForLibrary = p => () => RESTful(
	ErrandSuggestErrandForLibrary, p).post();
export const postErrandUnpublishErrand = p => () => RESTful(
	ErrandUnpublishErrand, p).post();
export const postErrandUploadAnswerAttachment = p => () => {
	return RESTful(ErrandUploadAnswerAttachment, p).postMultipart();
};
export const postAreaNotification = (p) => () => RESTful(GetAreaNotification, p).post();
export const getErrandPrint = p => () => RESTful(ErrandPrintContent, p).get();
export const postErrandPinToTop = (p) => () => RESTful(ErrandPinToTop, p).post();
export const postErrandUpdatePriority = (p) => () => RESTful(ErrandUpdatePriority, p).post();
export const postErrandUpdateLockToMe = (p) => () => RESTful(ErrandUpdateLockToMe, p).post();
export const postErrandUpdateDueDate = (p) => () => RESTful(ErrandUpdateDueDate, p).post();
export const postSocialMediaActions = (channel, p) => () => {
	let action = "errand/" + channel + "Actions";
	return RESTful(action, p).post();
};
export const postSocialMediaPMS = (channel, p) => () => {
	let action = "errand/" + channel + "SendPm";
	return RESTful(action, p).post();
};
export const postSocialMediaUpdateAns = (channel, p) => () => {
	let action = "errand/" + channel + "AnswerActions";
	return RESTful(action, p).post();
};
export const postFBEmotionList = (p) => () => RESTful(ErrandFBEmotions, p).post();
export const postFBRatings = (p) => () => RESTful(ErrandFBRatings, p).post();
export const postErrandFetchAreaTags = (p) => () => RESTful(ErrandFetchAreaTags, p).post();
export const postErrandWorkflowSettings = () => () => {
	return RESTful(ErrandFetchWorkflowSettings).post();
};
export const postErrandWorkflowLimitedSettings = () => () => {
    return RESTful(ErrandFetchWorkflowLimitedSettings).post();
};
export const postExternalexpertAnswerSave = p => () => RESTful(ExternalexpertAnswerSave, p).post();
export const postExternalexpertSend = p => () => RESTful(ExternalexpertSend, p).post();
export const postExternalexpertUpdate = p => () => RESTful(ExternalexpertUpdate, p).post();
export const postExternalexpertLastSave = p => () => RESTful(ExternalexpertLastSave, p).post();
export const postSalutations = p => () => RESTful(SalutationSave, p).post();
export const postSignatures = p => () => RESTful(SignatureSave, p).post();
export const postTemplates = p => () => RESTful(TemplateSave, p).post();
export const postQuickReply = p => () => RESTful(QuickReplySave, p).post();
export const postCreateExport = p => () => RESTful(CreateExport, p).post();
export const postGeneratedPreview = q => () => RESTful(GeneratedPreview, q).post();
export const postAddEntryToContactCard = q => () => RESTful(AddEntryToContactCard, q).post();
export const postCustomerNote = q => () => RESTful(AddCustomerNote, q).post();
export const postSetErrandPostponeDate = q => () => RESTful(SetErrandPostponeDate, q).post();
export const postDeleteCustomerNote = p => () => RESTful(DeleteCustomerNote, p).post();
export const postClientAvatar = q => () => RESTful(GetClientAvatar, q).postJSON();
export const postCustomerAvatar = q => () => RESTful(PostCustomerAvatar, q).postMultipart();
export const postCustomerDelAvatar = q => () => RESTful(PostCustomerDelAvatar, q).post();
export const postRecordEditorSize = q => () => RESTful(RecordEditorSize, q).post();
export const postGetErrandAcquireOwnerStatus = p => () => RESTful(GetErrandAcquireOwnerStatus, p).post();
export const postAgentWorkingOnErrand = q => () => RESTful(AgentWorkingOnErrand, q).post();
export const putAgentWorkingOnErrand = q => () => RESTful(AgentWorkingOnErrand, q).put();
export const getAgentWorkingOnErrand = q => () => RESTful(AgentWorkingOnErrand, q).get();
export const getIncomingEmailErrands = q => () => RESTful(IncomingEmailErrands, q).get();
export const getAllActiveErrands = q => () => RESTful(ActiveErrandList, q).get();
export const getAllActiveAgents = q => () => RESTful(ActiveAgents, q).get();
export const getCRMdatas = q => () => RESTful(CRMSystemData, q).get();
// POST one
export const postOneAdminPinReports = (id, p) => () =>
	RESTful(AdminPinReports, p).post(id);
export const postOneAdminReports = (id, p) => () =>
	RESTful(AdminReports, p).post(id);
export const postAdminReports = (p) => () =>
	RESTful(AdminReports, p).post();
export const postOneAdminReportsDrilldown = (id, p) => () => reportDrilldownREST(p).postJSON(id);
export const postOneBasicErrand = id => () => RESTful(ErrandBasic).post(id);
export const postOneErrandNotes = (id, p) => () => RESTful(ErrandNotes,
	p).post(id);
// TODO: switch to GET
export const postOneErrandPreview = id => () => RESTful(ErrandPreview).post(id);

// -----------------------------------------------------------------------------
// Translation
//
export const postTranslate = p => () => CentionREST(Translate, p).postJSON();
export const postDetectTranslate = p => () => CentionREST(TranslateDetect, p).postJSON();

// PUT
export const putSaveExport = (id, p) => () => RESTful(SaveExport, p).put(id);
export const putScheduledReport = (id, p) => () => RESTful(ScheduleReport, p).put(id);
export const putAdminReports = (id, p) => () => RESTful(AdminReports, p).put(id);
export const putTimeSpan = (id, p) => () => RESTful(TimeTimespan, p).put(id);
//PATCH
export const patchActivateExport = (id, p) => () => RESTful(ActivateExport, p).patch(id);
//DELETE
export const deleteAdminPinReports = id => () =>
	RESTful(AdminPinReports, p).delete(id);
export const deleteExport = id => () => RESTful(RemoveExport).delete(id);
export const deleteGeneratedExport = id => () => RESTful(RemoveGeneratedExport).delete(id);
export const deleteOneErrandNotes = (id, p) => () => RESTful(ErrandNotes,
	p).delete(id);
export const deleteContactCardEntry = id => () => RESTful(DeleteEntryFromContactCard).delete(id);
//file download
export const getExportFile = id => () => RESTful(FetchExportFile).download(id);
//get file simply via url
export const getFileContent = file => () => $.ajax({
	url: file,
	method: 'GET'
});

// Chat IP address blacklist
export const getChatIPAddressBlacklist = () => () => {
	return RESTful(ChatIPAddressBlacklist).get();
};
export const deleteChatIPAddressBlacklist = p => () => {
	//?id=
	return RESTful(ChatIPAddressBlacklist, p).delete();
};

// agent's chat source config
export const getAgentChatSourceConfig = () => () => RESTful(AgentChatSourceConfig).get();
export const postAgentChatSourceConfig = p => () => RESTful(AgentChatSourceConfig, p).post();

// agent's various favourite list, identified by unique name
export const getAgentFavourite = name => () => RESTful(AgentFavourite, {name}).get();
export const postAgentFavourite = (name, id) => () => RESTful(AgentFavourite, {name, id}).post();

export const postAventaLogin = p => () => RESTful(AgentAventaLogin, p).post();

export const getAvailableSipAgents = p => () => RESTful(GetAvailableSipAgents,p).post();
export const getAllSipAgents = p => () => RESTful(GetAllSipAgents, p).post();
export const getActiveCallLogs = p => () => RESTful(GetActiveCallLogs, p).post();
export const getSipNumberInUse = p => () => RESTful(GetSipNumberInUse, p).post();

// gpdr
export const getAnonymize = p => () => {
	return RESTful(Anonymize, p).get();
}
export const postAnonymize = p => () => {
	return RESTful(Anonymize, p).post();
}
export const postExportLog = (id,p) => () => {
	return RESTful(ExportLog, p).post(id);
}
export const deleteContactExport = (id, secret) => () => {
	console.log("Aja del ", id , secret);
	return RESTful(ContactExport).delete(id+"/"+secret);
}
export const postContactExport = (id, p) => () => {
	return RESTful(ContactExport, p).post(id);
}

//internal messages
export const getInternalMessageList = p => () => RESTful(InternalMessage, p).get();
export const getInternalMessage = id => () => RESTful(InternalMessage).get(id);

//internal message
export const getAgentCardEntry = id => () => RESTful(AgentcardsEntryList).get(id);
export const getAgentBook = q => () => RESTful(GetAgentBook,q).get();
export const getGroupBook = q => () => RESTful(GetGroupBook,q).get();
export const postIMSendMessage = p => () => RESTful(IMSendMessage,p).post();
export const getIMCounter = id => () => RESTful(IMCounter).get(id);
export const postDeleteIMs = p => () => RESTful(IMDeleteMessages, p).post();
export const postUpdateMsgRead = p => () => RESTful(IMMessageRead, p).post();
export const postIMSaveDraft = p => () => RESTful(IMSaveDraft, p).post();
export const getAgentGroups = () => () => RESTful(GetAgentGroups).get();
export const getNewIMCount = q => () => RESTful(NewIMCount,q).get();
export const postRestoreIMs = p => () => RESTful(IMRestoreMessages, p).post();
export const getInternalMessageHistory = (id,param) => () => RESTful(InternalMessageHistory, param).get(id);

// Membershipd
export const getMembershipStatus = q => () => $.ajax({
	url: Membershipd,
	data: q,
	method: 'GET'
});

// Admin
//Agent
export const getAgentData = p => () => RESTful(AgentAdmin).get(p);
export const updateAgentData = p => () => RESTful(AgentEdit, p).post();
export const getAgentList = p => () => RESTful(AgentList, p).get();
export const getAgentNew = p => () => RESTful(AgentNew).get(p);
export const getAgentSalutations = p => () => RESTful(AgentGetSalutations, p).get();
export const getAgentSignatures = p => () => RESTful(AgentGetSignatures, p).get();
export const addAgentMP = p => () => RESTful(AgentAddMP, p).post();
export const removeAgentMP = p => () => RESTful(AgentRemoveMP, p).post();
export const addAgentAvatar = p => () => RESTful(AgentUploadAvatar, p).postMultipart();
export const removeAgentAvatar = p => () => RESTful(AgentRemoveAvatar, p).postMultipart();
export const getAgentValidateExternalID = p => () => RESTful(AgentValidateExternalID, p).get();
export const activateAgent = p => () => RESTful(AgentActivate).get(p);
export const deactivateAgent = p => () => RESTful(AgentDeactivate).get(p);
export const unlockAgent = p => () => RESTful(AgentUnlock).get(p);
export const uploadAgentList = p => () => RESTful(AgentUploadList, p).postMultipart();
export const uploadAutoTagList = p => () => RESTful(AutoTagUploadList, p).postMultipart();
export const checkAgentImportStatus = p => () => RESTful(AgentImportStatus).get(p);
export const validateAgentLoginName = p => () => RESTful(AgentValidate, p).post();
export const agentEmailAvailability = p => () => RESTful(AgentEmailAvailable, p).post();

// Admin's agent status
export const getAgentStatusList = p => () => RESTful(AdminAgentStatusList, p).get();
export const getAgentSipStatus = p => () => RESTful(AdminAgentSipStatus, p).get();
export const postAgentSipStatus = p => () => RESTful(AdminAgentSipStatus, p).post();
export const deleteAgentSipStatus = p => () => RESTful(AdminAgentSipStatus, p).delete();
export const getAgentSipStatusValidate = p => () => RESTful(AdminAgentSipStatusValidate, p).get();

//stun/turn servers
export const getStunTurnList = p => () => RESTful(AdminStunTurn, p).get();
export const saveStunTurn = p => () => RESTful(AdminStunTurn, p).post();
export const removeStunTurn = p => () => RESTful(AdminStunTurn, p).delete();
export const stunTurnStatus = p => () => RESTful(AdminStunTurnStatus, p).post();

// Classifier
export const getClassifier = p => () => RESTful(AdminClassifier, p).get();
export const postClassifier = p => () => RESTful(AdminClassifier, p).post();
export const deleteClassifier = p => () => RESTful(AdminClassifier, p).delete();

//Wordlist
export const getAgentWordList = p => () => RESTful(Wordlist, p).get();
export const loadAgentWordList = p => () => RESTful(WordlistEdit, p).get();
export const saveAgentWordList = p => () => RESTful(WordlistSave, p).post();
export const removeAgentWordlist = p => () => RESTful(WordlistDelete).get(p);

//Addressbook
export const getAddressbookList = p => () => RESTful(AdminGlobalAddressbookList, p).get();
export const editAddressbook = p => () => RESTful(AdminGlobalAddressbookEdit, p).post();
export const saveAddressbook = p => () => RESTful(AdminGlobalAddressbookSave, p).post();
export const removeAddressbook = p => () => RESTful(AdminGlobalAddressbookDelete, p).get();
export const uploadAddressbook = p => () => RESTful(AdminGlobalAddressbookUpload, p).postMultipart();

//Errand status aka state
export const getAdminErrandStateList = id => () => RESTful(AdminErrandInternalState).get(id);

//Companies
export const getCompanyList = p => () => RESTful(CompanyList, p).get();
export const saveNewCompany = p => () => RESTful(CompanyNew, p).post();
export const removeCompany = p => () => RESTful(CompanyRemove, p).delete();

//Admin Config Change Log
export const getConfigChangeLog = p => () => RESTful(ConfigChangeLog, p).get();

//External expert address book
export const getCollaboratorList = () => () => RESTful(ExternalexpertAddressList, {complete: true}).get();
export const getExternalExpertAddressList = p => () => RESTful(ExternalexpertAddressList, p).get();
export const saveExternalExpertAddressList = p => () => RESTful(ExternalexpertAddressSave, p).postMultipart();
export const deleteExternalExpertAddressList = p => () => RESTful(ExternalexpertAddressRemove, p).delete();
export const removeEEAddressAvatar = p => () => RESTful(ExternalexpertAddressRemoveAvatar, p).post();

//ADMIN ACCOUNTS
//commons/generic
export const activateAccount = (p) => () => {
	return RESTful(p.endpoint, undefined, urlAccountEnabler).delete(p);
}
export const getAccount = ( endpt, p ) => () => RESTful(endpt, p).get();
export const getOneAccount = ( endpt, p ) => () => {
	function urlCreator(url, p) {
		return url + `/${p.id}/area/${p.area}`;
	}
	return RESTful(endpt, null, urlCreator).get(p);
}
export const postAccount = ( endpt, p ) => {
	if(p instanceof FormData){
		return () => RESTful(endpt, p).postMultipart();
	}
	return () => RESTful(endpt, p).post();
}
export const deleteAccount = ( endpt, p) => () => {
	function urlCreator(url, p) {
		return url + `/area/${p.area}/acid/${p.id}/remove`;
	}
	return RESTful(endpt, undefined, urlCreator).delete(p);
}
//facebook post
export const postAccountFacebook = (p, type) => () => {
	let endpt = AdminAccountFacebook;
	if(type === "minimal") {
		endpt = AdminAccountFacebookSave;
	}
	return RESTful(endpt, p).post();
}
//slack save
export const postAccountSlack = ( endpt, p ) => {
	let params = { params: JSON.stringify(p) };
	if(p instanceof FormData){
		return () => RESTful(endpt, params).postMultipart();
	}
	return () => RESTful(endpt, params).post(p.id);
}
//twilio get one
export const getOneAccountTwilio = ( endpt, p ) => () => {
	function urlCreator(url, p) {
		return url + `/${p.aid}/phones/${p.id}`;
	}
	return RESTful(endpt, null, urlCreator).get(p);
}
//twilio save
export const postAccountTwilio = ( endpt, p ) => {
	let twilioUrl = endpt;
	let params = JSON.stringify(p);
	if(typeof p.pid !== "undefined"){
		twilioUrl = twilioUrl = endpt + `/${p.aid}/phones/${p.pid}`;
	}
	if(p instanceof FormData){
		return () => RESTful(twilioUrl, params).postMultipart();
	}
	return () => RESTful(twilioUrl, params).post();
}
//twilio delete
export const deleteAccountTwilio = ( endpt, p) => () => {
	function urlCreator(url, p) {
		return url + `/${p.aid}/phones/${p.id}`;
	}
	return RESTful(endpt, undefined, urlCreator).delete(p);
}
//Twilio activation
export const activateTwilio = ( p ) => {
	let url = p.endpoint;
	let params = JSON.stringify({
		id: p.id,
		pid: p.pid,
		enable: p.enable
	});
	return () => RESTful(url, params).post();
}
//SMPP edit
export const getOneAccountSMPP = ( endpt, p ) => () => {
	function urlCreator(url, p) {
		return url + `?id=${p.id}`;
	}
	return RESTful(endpt, null, urlCreator).get(p);
}
//SMPP Delete
export const deleteAccountSMPP = ( endpt, p) => () => {
	function urlCreator(url, p) {
		return url + `?id=${p.id}`;
	}
	return RESTful(endpt, undefined, urlCreator).delete(p);
}
//SMPP activation
export const activateSMPP = ( p ) => () => {
	return RESTful(p.endpoint, p.id).get(p.id);
}
//slack activation
export const activateSlack = p => () => {
	return RESTful(p.endpoint).delete(p.id);
}
//slack delete
//(tofix: maybe slack can follow common 'deleteAccount' function
//if areaId is included)
export const deleteAccountSlack = ( endpt, p) => () => {
	let url = endpt + '/deactivate'
	return RESTful(url).delete(p.id);
}

//File Archive
export const getFileArchive = p => () => RESTful(AdminFileArchive, p).get();
// export const editFileArchive = p => () => RESTful(AdminFileArchiveEdit, p).postMultipart();
export const saveFileArchive = p => () => RESTful(AdminFileArchive, p).postMultipart();
export const deleteFileArchive = p => () => RESTful(AdminFileArchive, p).delete();
export const downloadFileArchive = p => () => {
	function withQueryDownload(endpoint, id) {
		let query = "archive";
		return endpoint + `?${query}=` + id
	}
	return RESTful(AdminFileArchiveDownload, null, withQueryDownload).download(p);
}
//Blacklist
export const getBlacklistWords = p => () => RESTful(Blacklist, p).get();
export const deleteBlacklistWords = p => () => RESTful(Blacklist, p).delete();
export const postBlacklistWords = p => () => RESTful(Blacklist , p).postMultipart();

//Blacklist response 
export const getBlacklistResponse = p => () => RESTful(BlacklistResp, p).get();
export const deleteBlacklistResponse = p => () => RESTful(BlacklistResp, p).delete();
export const postBlacklistResponse = p => () => RESTful(BlacklistResp , p).postMultipart();

//Whitelist
export const getWhitelistWords = p => () => RESTful(Whitelist, p).get();
export const deleteWhitelistWords = p => () => RESTful(Whitelist, p).delete();
export const postWhitelistWords = p => () => RESTful(Whitelist , p).postMultipart();

// Tool Manager 
export const getToolManager = p => () => RESTful(ToolManager, p).get();
export const deleteToolManager = p => () => RESTful(ToolManager, p).delete();
export const postToolManager = p => () => RESTful(ToolManager , p).postMultipart(); 

//Routing Rules
export const getRouteKeywords = p => () => RESTful(RouteKeywords, p).get();
export const deleteRouteKeywords = p => () => RESTful(RouteKeywords, p).delete();
export const getOneRouteKeywords = p => () => RESTful(RouteKeywords, p).get();
export const postRouteKeywords = p => () => RESTful(RouteKeywords, p).post();

export const getRouteAutoTags = p => () => RESTful(RouteAutoTags, p).get();
export const getOneRouteAutoTags = p => () => RESTful(RouteAutoTags, p).get();
export const deleteRouteAutoTags = p => () => RESTful(RouteAutoTags, p).delete();
export const postRouteAutoTags = p => () => RESTful(RouteAutoTags, p).post();
export const getRouteSip = p => () => RESTful(RouteSip, p).get();
export const postRouteSip = p => () => RESTful(RouteSip, p).post();
export const getOneRouteSip = p => () => RESTful(RouteSip, p).get();
export const deleteRouteSip = p => () => RESTful(RouteSip, p).delete();


export const getAdminGroups = p => () => RESTful(AdminGroups, p).get();
export const getOneAdminGroups = p => () => RESTful(AdminGroups, p).get();
export const postAdminGroups = p => () => RESTful(AdminGroups, p).post();
export const deleteAdminGroups = p => () => RESTful(AdminGroups, p).delete();

export const getGroupFolder = p => () => RESTful(GroupFolders, p).get();
export const getOneGroupFolder = p => () => RESTful(GroupFolders, p).get();
export const postGroupFolder = p => () => RESTful(GroupFolders, p).post();
export const deleteGroupFolder = p => () => RESTful(GroupFolders, p).delete();

export const getGroupAgents = p => () => RESTful(GroupAgents, p).get();
export const getOneGroupAgents = p => () => RESTful(GroupAgents, p).get();
export const postGroupAgents = p => () => RESTful(GroupAgents, p).post();
export const deleteGroupAgents = p => () => RESTful(GroupAgents, p).delete();

export const getCorsWhitelist = p => () => RESTful(CORSWhitelist, p).get();
export const getOneCorsWhitelist = p => () => RESTful(CORSWhitelist, p).get(p);
export const postCorsWhitelist = p => () => RESTful(CORSWhitelist, p).post();
export const deleteCorsWhitelist = p => () => RESTful(CORSWhitelist).delete(p);

export const getCallRecordings = p => () => RESTful(CallRecordings, p).get();
export const getOneCallRecordings = p => () => {
	function urlCreator(url, p) {
		return url + `?id=${p}`;
	}
	return RESTful(CallRecordings, null, urlCreator).get(p);
}

export const getAgentSipLogins = p => () => RESTful(AgentSipLogin, p).get();
export const getOneAgentSipLogin = p => () => {
	function urlCreator(url, p) {
		return url + `?id=${p}`;
	}
	return RESTful(AgentSipLogin, null, urlCreator).get(p);
}
export const postSaveAgentSipLogin = p => () => RESTful(AgentSipLogin, p).post();
export const deleteAgentSipLogin = p => () => RESTful(AgentSipLogin).delete(p);

export const getSipPriorityNumbers = p => () => RESTful(SipPriorityNumbers, p).get();
export const getSipOnePrioNumber = p => () => {
	function urlCreator(url, p) {
		return url + `?id=${p}`;
	}
	return RESTful(SipPriorityNumbers, null, urlCreator).get(p);
}
export const postSaveSipPrioNumber = p => () => RESTful(SipPriorityNumbers, p).post();
export const deleteSipPrioNumber = p => () => RESTful(SipPriorityNumbers).delete(p);

//contact cards
export const getContactCard = () => () => RESTful(ContactCards).get();
export const getContactCardNotes = p => () => RESTful(ErrandNotes, p).get();
export const deleteContactCardNote = p => () => RESTful(ErrandNotes, p).delete(p.id);
export const getOneContactCard = p => () => RESTful(ContactCards, p).get(p);
export const getContactCardByKeywords = p => () => {
	function urlCreator(url, p) {
		return url + `?searchText=${p}`;
	}
	return RESTful(ContactCards, null, urlCreator).get(p);
}
export const postContactCard = p => () => RESTful(SaveContactCards ,p).post();
export const postMergeContactCard = p => () => RESTful(MergeContactCards ,p).post();
export const deleteContactCardAccount = p => () => RESTful(SaveContactCards, p).delete(p);
export const deleteContactCard = id => () => RESTful(ContactcardsList).delete(id);
// export const getOneContactCard = p => () => RESTful(ContactCards, p).get();
// export const postContactCard = p => () => RESTful(ContactCards, p).post();

export const getRoutingGroups = p => () => RESTful(RoutingGroups, p).get();
export const deleteRoutingGroups = p => () => RESTful(RoutingGroups, undefined, p).delete(p);
export const getOneRoutingGroups = p => () => RESTful(RoutingGroups, p).get(p);
export const postRoutingGroups = p => () => RESTful(RoutingGroups, p).postMultipart();

// Admin's API Callback
export const getCallbackAPIList = p => () => RESTful(AdminCallbackAPI, p).get();
export const getCallbackListAPIById = p => () => RESTful(AdminCallbackAPI, p).get(p);
export const deleteCallbackListAPI = p => () => RESTful(AdminCallbackAPI).delete(p);
export const putCallbackListAPI = p => () => RESTful(AdminCallbackAPI, p).put(id);
export const postCallbackListAPI = p => () => {
	return RESTful(AdminCallbackAPI, p).post();
};

// Admin's JWT Access Token
export const getJWTList = p => () => RESTful(AdminJWTAPI, p).get();
export const postJWTAPI = p => () => RESTful(AdminJWTAPI, p).post();
export const deleteJWTAPI = p => () => RESTful(AdminJWTAPI, p).delete();

// Knowledge Bases
export const deleteKnowledgeBaseNode = p => () => RESTful(AdminLibraryTreeRemove, p).post();
export const getKnowledgeBaseCategory = (id,param) => () => {
	return RESTful(ErrandKnowledgeBaseCategory,param).get(id);
};
export const getKnowledgeBaseRoot = () => () => RESTful(AdminLibraryTreeRoots).get();
export const insertKnowledgeBaseNode = p => () => RESTful(AdminLibraryTreeInsert, p).post();

// Get List Knowledge Base
export const getAdminLibrary = (id) => () => RESTful(AdminLibrary).get(id);
export const getAdminLibraryCategory = (id) => () => RESTful(AdminLibraryCategory).get(id);
export const getAdminLibraryQuestion = (id) => () => RESTful(AdminLibraryQuestion).get(id);
export const getAdminLibrarySuggestion = (id) => () => RESTful(AdminLibrarySuggestion).get(id);
export const getAdminLibraryRating = (id) => () => RESTful(AdminLibraryRating).get(id);

// Save Knowledge Base
export const saveAdminLibrary = (p) => () => RESTful(AdminLibrary, p).post();
export const saveAdminLibraryCategory = (p) => () => RESTful(AdminLibraryCategory, p).post();
export const saveAdminLibraryQuestion = (p) => () => RESTful(AdminLibraryQuestion, p).post();
export const saveAdminLibraryRating = (p) => () => RESTful(AdminLibraryRating, p).post();

// Remove Knowledge Base
export const removeAdminLibrary = (id) => () => RESTful(AdminLibraryRemove).get(id);
export const removeAdminLibraryCategory = (id) => () => RESTful(AdminLibraryCategoryRemove).get(id);
export const removeAdminLibraryQuestion = (id) => () => RESTful(AdminLibraryQuestionRemove).get(id);
export const removeAdminLibraryRating = (id) => () => RESTful(AdminLibraryRatingRemove).get(id);

// Fetch Table List
export const fetchLibraryList = p => () => RESTful(AdminLibraryList, p).get();
export const fetchCategoryList = p => () => RESTful(AdminLibraryCategoryList, p).get();
export const fetchQuestionList = p => () => RESTful(AdminLibraryQuestionList, p).get();
export const fetchSuggestionList = p => () => RESTful(AdminLibrarySuggestionList, p).get();
export const fetchRatingList = p => () => RESTful(AdminLibraryRatingList, p).get();

// Fetch more table List
export const fetchMoreLibraryList = p => () => RESTful(AdminLibraryList, p).get();
export const fetchMoreCategoryList = p => () => RESTful(AdminLibraryCategoryList, p).get();
export const fetchMoreQuestionList = p => () => RESTful(AdminLibraryQuestionList, p).get();
export const fetchMoreSuggestionList = p => () => RESTful(AdminLibrarySuggestionList, p).get();
export const fetchMoreRatingList = p => () => RESTful(AdminLibraryRatingList, p).get();

//Chat Widget
export const saveNewChatWidgetConfig = p => () => RESTful(ChatWidgetCfgNew, p).post();
export const getChatWidgetCfgList = p => () => RESTful(ChatWidgetCfgList, p).get();
export const getChatWidgetCfgDefList = p => () => RESTful(ChatWidgetCfgDefList, p).get();
export const removeChatWidgetCfg = p => () => RESTful(ChatWidgetCfgDelete, p).delete();

//FAQ Widget
export const saveNewFaqWidgetConfig = p => () => RESTful(FaqWidgetCfgNew, p).post();
export const getFaqWidgetCfgList = p => () => RESTful(FaqWidgetCfgList, p).get();
export const getFaqWidgetCfgDefList = p => () => RESTful(FaqWidgetCfgDefList, p).get();
export const removeFaqWidgetCfg = p => () => RESTful(FaqWidgetCfgDelete, p).delete();

//Voice/Callback Widget
export const saveNewVoiceWidgetConfig = p => () => RESTful(VoiceWidgetCfgNew, p).post();
export const getVoiceWidgetCfgList = p => () => RESTful(VoiceWidgetCfgList, p).get();
export const getVoiceWidgetCfgDefList = p => () => RESTful(VoiceWidgetCfgDefList, p).get();
export const removeVoiceWidgetCfg = p => () => RESTful(VoiceWidgetCfgDelete, p).delete();

export const getCreateManualErrand = p => () => RESTful(CreateManualErrand, p).get();

//Tag
export const getTags = p => () => RESTful(TagKeywords, p).get();
export const getNewOrganisations = p => () => RESTful(AdminOrganisation, p).get(); 
export const GetOrganisationById = id => () => RESTful(AdminGetOrganisationById).get(id); 
export const SaveOrganisationExternal = p => () => RESTful(`admin/org/${p.id}/exturl`,p.url).post(); 
export const SaveOrganisationAreaExternal = p => () => RESTful(`admin/area/${p.id}/exturl`,p.url).post(); 
export const DeleteOrganisationExternal = p => () => RESTful(`admin/org/${p.id}/exturl`,p.url).delete();

//IVR
export const getIVRList = p => () => RESTful(CallAdminIVR, p).get();
export const saveIVR = p => () => RESTful(CallAdminIVR, p).post();
export const removeIVR = p => () => RESTful(CallAdminIVR).delete(p);
export const uploadIVRPrompt = p => () => RESTful(CallAdminIVRPromptUpload, p).postMultipart();
export const uploadIVRMOH = p => () => RESTful(CallAdminIVRMOHUpload, p).postMultipart();
export const deployIVR = p => () => RESTful(CallAdminIVRDeploy, p).post();

//User's launchpad
export const fetchForwardedErrands = p => () => RESTful(FetchForwardedErrands, p).post();
export const fetchIncomingCollaborations = p => () => RESTful(FetchIncomingCollaborations, p).post();
export const fetchExpiringErrands = p => () => RESTful(FetchExpiringErrands, p).post();
export const fetchDueErrandsInAWeek = p => () => RESTful(FetchDueErrandsInAWeek, p).post();
export const deleteLaunchpadWidget = p => () => RESTful(DeleteLaunchpadWidget).delete(p);
export const addNewLaunchpadWidget = p => () => RESTful(AddNewLaunchpadWidget, p).post();
// TowFA
export const getTwoFAGetNewSecret = () => () => RESTful(TwoFANewSecret).get();
export const verifyTwoFAToken = p => () => RESTful(VerifyTwoFAToken,p).get();

//SIP Trunk
export const getSipTrunkList = p => () => RESTful(CallAdminSipTrunk, p).get();
export const getSipTrunk = p => () => {
	function urlCreator(url, p) {
		return url + `?id=${p}`;
	}
	return RESTful(CallAdminSipTrunk, null, urlCreator).get(p);
}
export const postSaveSipTrunk = p => () => RESTful(CallAdminSipTrunk, p).post();
export const deleteSipTrunk = p => () => RESTful(CallAdminSipTrunk).delete(p);

//Skills
export const postSaveSkillsCategory = p => () => RESTful(SkillsCategory, p).post();
export const postSaveSkills = p => () => RESTful(Skills, p).post();
export const postSaveSkillAgent = p => () => RESTful(SkillAgent, p).post();
export const postSaveSkillArea = p => () => RESTful(SkillArea, p).post();
export const getSkillsCategory = p => () => RESTful(SkillsCategory, p).get();
export const getSkills = p => () => RESTful(Skills, p).get();
export const getSkillAgents = id => () => RESTful(SkillAgent).get(id);
export const getSkillAreas = id => () => RESTful(SkillArea).get(id);
export const deleteSkill = p => () => RESTful(Skills).delete(p);
export const deleteSkillCategory = p => () => RESTful(SkillsCategory).delete(p);
export const deleteSkillAgent = p => () => RESTful(SkillAgent).delete(p);
export const deleteSkillArea = p => () => RESTful(SkillArea).delete(p);
export const getSkillsProficiency = p => () => RESTful(SkillProf, p).get();

//Agent Assist
export const getAgentAssist = p => () => RESTful(AgentAssist, p).get();
export const saveAgentAssist = p => () => RESTful(AgentAssist, p).post();

// Generative AI Documents
export const getGenerativeAIDocument = p => () => RESTful(AdminGenerativeAIDocument, p).get();
export const postGenerativeAIDocument = p => () => RESTful(AdminGenerativeAIDocument, p).postMultipart();
export const deleteGenerativeAIDocument = p => () => RESTful(AdminGenerativeAIDocument, p).delete();
export const getLanguageList = p => () => RESTful(AdminLanguageList, p).get();